import { useDashboard } from "../useDashboard";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { PositionManagementView } from "./PositionManagementView";

export const PositionManagementDashboard = ({ visible, }) => {
    const dashboardKey = 'position-management';
    const useShared = true;

    const Dashboard = useDashboard({ dashboardKey, useShared, ViewComponent: PositionManagementView, AddNewOrUpdateDialog, visible, });
    return Dashboard;
}