import { useImperativeHandle, useRef, useMemo, useState, useCallback, forwardRef } from "react"
import { Box } from "@mui/material"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import dayjs from "dayjs"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { tooltipRenderer, redPastMarkerFormatter, } from "../Utils"
import { DealDetailCellRenderer } from "./DealDetailCellRenderer"
import useHubObject from "../../HubContext/useHubObject"
import { useUserInfo } from "../../UserInfoContext"
import { useApi } from "../../useApi"
import { useDashboardFetch } from "../useDashboardFetch"
import * as yup from 'yup';
import { ViewContextProvider } from "../ViewContextProvider"
import { ScheduleSummaryRibbon } from "./Ribbon/ScheduleSummaryRibbon"
import { useLayoutFunctions } from "../../useLayoutFunctions"
import { ScheduleSummaryGrid } from "./ScheduleSummaryGrid"

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    startDate: yup.date().required('Start Date is required'),
    endDate: yup.date().required('End Date is required'),
});

export const ScheduleSummaryView = forwardRef(({ view, }, ref) => {
    const layoutStorageKey = `deal-rizz-schedule-summary-grid-layout-${view.id}`;
    const gridRef = useRef();
    const [selectedRow, setSelectedRow] = useState();
    const [selectedDeal, setSelectedDeal] = useState();
    const userInfo = useUserInfo();
    const toolbarFormId = 'schedule-summary-toolbar';
    const { enqueueSnackbar, logAction, headers, } = useApi();
    const { captureLayout } = useLayoutFunctions();

    const defaults = useMemo(() => {
        return {
            timezone: 'Pacific Standard Time',
            ...view,
            startDate: dayjs(),
            endDate: dayjs().add(1, 'day'),
        }
    }, [view]);

    useHubObject({
        action: async (obj) => {
            enqueueSnackbar('Schedule changes detected, refreshing...', { variant: 'info' });
            //programmatically click the refresh button to update the grid
            document.getElementById(`${toolbarFormId}-refresh`).click();
        },
        allowedMessages: ['scheduleUpdate'],
        callbackDependencies: [headers],
        predicate: (obj) => {
            return obj.tenantID.toString() === userInfo.tenantId.toString();
        },
        debounceOptions: { maxWait: 1000, leading: true, },
        wait: 500,
    });

    const getRowId = useCallback(({ data }) => data.scheduleID, []);


    const dataFormatter = useCallback((data) => ({
        ...data,
        userGuid: headers.userGuid,
        startDate: dayjs(data.startDate).format('MM/DD/YYYY'),
        endDate: dayjs(data.endDate).format('MM/DD/YYYY'),
    }), [headers]);

    const { loadAndApplyData, silentUpdate, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_fetchScheduleSummary_v4',
        fetchParamKeys: ['userGuid', 'startDate', 'endDate', 'timezone'],
        gridRef,
        getRowId,
        dataFormatter,
    });

    const handleFetchData = useCallback((data) => {
        logAction('User fetched Schedule Summary Data', 'Schedule Summary', data);
        loadAndApplyData(data);
    }, [headers]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });

    function handleRowVoidUpdate(scheduleID, toVoid) {
        const node = gridRef.current.api.getRowNode(scheduleID);
        if (node) {
            gridRef.current.api.applyTransaction({ update: [{ ...node.data, Schedule_Status: toVoid ? 'VOIDED' : 'DRAFT' }] });
        }
    }

    function handleRowConfirmUpdate(scheduleID, toConfirm) {
        const node = gridRef.current.api.getRowNode(scheduleID);
        if (node) {
            gridRef.current.api.applyTransaction({ update: [{ ...node.data, Schedule_Status: toConfirm ? 'CONFIRMED' : 'DRAFT' }] });
        }
    }

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box sx={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
                <ScheduleSummaryRibbon
                    toolbarFormId={toolbarFormId}
                    handleFetchData={handleFetchData}
                    selectedRow={selectedRow}
                    selectedDeal={selectedDeal}
                    handleRowVoidUpdate={handleRowVoidUpdate}
                    handleRowConfirmUpdate={handleRowConfirmUpdate}
                />
                <ScheduleSummaryGrid
                    setSelectedDeal={setSelectedDeal}
                    setSelectedRow={setSelectedRow}
                    getRowId={getRowId}
                    layoutStorageKey={layoutStorageKey}
                    ref={gridRef}
                    silentUpdate={silentUpdate}
                />
            </Box>
        </ViewContextProvider>
    )
});