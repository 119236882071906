import { Box } from "@mui/material";
import { Tooltip } from "@mui/material"
import { useState, } from "react";
import { RibbonButton } from "../../Ribbon";
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import { ScheduleDialog } from "../ScheduleDialog";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useApi } from "../../../useApi";
import { CreateDealButton, EditDealButton, RefreshButton } from "../../RibbonButtons";
import { useFormContext } from "react-hook-form";

export const ActionsGroup = ({ formId, expanded, selectedRows, sourceSinkOptions, scheduleFormId, handleFetchData }) => {
    const { enqueueSnackbar, logAction, get, apiUrlPrefix, headers, } = useApi();
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const allowSchedule = !!selectedRows.purchases?.length && !!selectedRows.sales?.length;
    const allowQuickSchedule = selectedRows.purchases?.length == 1 && selectedRows.sales?.length == 1 && !!selectedRows.tag;
    const onePurchase = selectedRows.purchases?.length === 1;
    const oneSale = selectedRows.sales?.length === 1;
    const allowEditDeal = !!onePurchase ^ !!oneSale; //exactly one purchase or one sale
    const dealToEdit = selectedRows.purchases[0] ?? selectedRows.sales[0];
    const { watch } = useFormContext();
    const timezone = watch('timezone');

    async function insertQuickSchedule() {

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_QuickScheduleInsert_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedRows.purchases[0].dealID ?? ''}`
            + `&parm=${selectedRows.sales[0].dealID ?? ''}`
            + `&parm=${selectedRows.tag.firstPOR ?? ''}`
            + `&parm=${selectedRows.tag.lastPOD ?? ''}`
            + `&parm=${selectedRows.tag.Source ?? ''}`
            + `&parm=${selectedRows.tag.Sink ?? ''}`
            + `&parm=${selectedRows.tag.tagIdx ?? ''}`
            + `&parm=${timezone ?? ''}`

        return get(url).then(response => {
            enqueueSnackbar('Quick schedule created successfully.', { variant: 'success' });
            logAction('User created quick schedule', 'Deal Rizz Scheduling', { deals: selectedRows });
        });
    }

    async function insertFollowTagSchedule() {

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_FollowTagScheduleInsert_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedRows.purchases[0].dealID ?? ''}`
            + `&parm=${selectedRows.sales[0].dealID ?? ''}`
            + `&parm=${selectedRows.tag.firstPOR ?? ''}`
            + `&parm=${selectedRows.tag.lastPOD ?? ''}`
            + `&parm=${selectedRows.tag.Source ?? ''}`
            + `&parm=${selectedRows.tag.Sink ?? ''}`
            + `&parm=${selectedRows.tag.tagIdx ?? ''}`
            + `&parm=${timezone ?? ''}`

        return get(url).then(response => {
            enqueueSnackbar('Schedule created successfully. Tag profile changes will effect schedule and deal profiles.', { variant: 'success' });
            logAction('User created follow tag schedule', 'Deal Rizz Scheduling', { deals: selectedRows });
        });
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', p: 1, }}>
            <ScheduleDialog
                open={openScheduleDialog}
                handleCancel={() => setOpenScheduleDialog(false)}
                selectedRows={selectedRows}
                formId={scheduleFormId}
                sourceSinkOptions={sourceSinkOptions}
            />

            <RefreshButton
                formId={formId}
                expanded={expanded}
                label={'Refresh All'}
                onRefresh={handleFetchData}
            />

            <Tooltip title='Select a purchase and a sale below, then click here to create a schedule. '>
                <span>
                    <RibbonButton
                        disabled={!allowSchedule}
                        icon={<CalendarViewMonthIcon fontSize='small' />}
                        label={'Schedule'}
                        expanded={expanded}
                        onMouseDown={() => setOpenScheduleDialog(true)}
                    />
                </span>
            </Tooltip>

            <Tooltip title='Select a purchase, a sale, and a tag to create a quick schedule based on the tag profile. '>
                <span>
                    <RibbonButton
                        disabled={!allowQuickSchedule}
                        icon={<ScheduleSendIcon fontSize='small' />}
                        label={'Quick Schedule'}
                        expanded={expanded}
                        onMouseDown={() => insertQuickSchedule()}
                    />
                </span>
            </Tooltip>

            <Tooltip title='Select a purchase, a sale, and a tag to create a schedule that is dynamically linked to the tag profile. '>
                <span>
                    <RibbonButton
                        disabled={!allowQuickSchedule}
                        icon={<LocalOfferIcon fontSize='small' />}
                        label={'Follow Tag'}
                        expanded={expanded}
                        onMouseDown={() => insertFollowTagSchedule()}
                    />
                </span>
            </Tooltip>

            <CreateDealButton
                expanded={expanded}
                formId={formId}
                label={'Create Deal'}
            />

            <EditDealButton
                expanded={expanded}
                disabled={!allowEditDeal}
                deal={dealToEdit}
                fetchDealData
                timezone={timezone}
                formId={formId}
                label={'Edit Deal'}
            />
        </Box>
    )
}