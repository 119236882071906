import CheckIcon from '@mui/icons-material/Check';
import { RibbonButton } from "../Ribbon"
import { useApi } from "../../useApi";
import { useCallback } from "react";
import debounce from 'lodash/debounce';

export const ConfirmDealButton = ({ deal, disabled, expanded, gridRef, ...buttonProps }) => {
    const { headers, apiUrlPrefix, get, logAction, enqueueSnackbar, } = useApi();

    const confirmButtonText = deal && !!deal.confirmed ? 'Unconfirm' : 'Confirm';
    const buttonDebounce = 1500;

    const handleConfirmDeal = useCallback(debounce(async (dealData) => {
        const confirmed = dealData.confirmed;
        logAction(`User ${confirmed ? 'un' : ''}confirmed deal with id ${dealData.dealID}`, 'Deal Summary', dealData);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_confirmDeal`
            + `&parm=${headers.userGuid}`
            + `&parm=${dealData.dealID}`
            + `&parm=${confirmed ? 0 : 1}`

        return get(url).then(response => {
            enqueueSnackbar(`Deal ${confirmed ? 'un' : ''}confirmed successfully`, { variant: 'success' });
            //use transaction to update the row
            gridRef?.current.api?.applyTransaction({ update: [{ ...dealData, confirmed: !confirmed }] });
            gridRef?.current.api?.deselectAll();
        });
    }, buttonDebounce, { leading: true, }), [headers]);

    return (
        <span>
            <RibbonButton
                onMouseDown={() => handleConfirmDeal(deal)}
                icon={<CheckIcon fontSize='small' />}
                label={confirmButtonText}
                expanded={expanded}
                disabled={disabled}
                {...buttonProps}
            />
        </span>
    )
}