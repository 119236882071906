import { IconButton, Stack, TextField, Box, FormControlLabel, Switch, } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, } from "@mui/x-date-pickers";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import FormAutocomplete from "../../../FormControls/FormAutocomplete";
import FormDatePicker from "../../../FormControls/FormDatePicker";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export const ViewForm = ({ onSubmit, formId }) => {
    const { control, handleSubmit, getValues, setValue, formState: { errors, }, } = useFormContext();
    const [search, setSearch] = useSearchParams();
    const filterHelper = (search.get('filterHelper') === 'true');

    useEffect(() => {
        if (!search.has('filterHelper')) {
            setSearch({ filterHelper: false, });
        }
    }, []);

    function handleToggleFilterHelper() {
        setSearch({ filterHelper: !filterHelper });
    }

    function handleIncrementDates(days) {
        const start = getValues('startDate');
        setValue('startDate', start.add(days, 'day'));
    }

    return (
        <Box
            sx={{ width: '100%', height: '100%', borderRadius: '0px', display: 'flex', alignItems: 'center', }}
            component='form'
            onSubmit={handleSubmit(onSubmit)}
            id={formId}
        >
            <Stack direction='row' spacing={2} alignItems='stretch' sx={{ pt: 1, display: 'flex', flexWrap: 'wrap', rowGap: '16px' }}>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDates(-1);
                        }}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <FormDatePicker
                            name='startDate'
                            control={control}
                            label="Flow Date"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.startDate,
                                    helperText: errors.startDate?.message,
                                    sx: { minWidth: '140px', maxWidth: '150px', }
                                }
                            }}
                        />&nbsp;
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDates(1);
                        }}>
                            <ArrowRightIcon />
                        </IconButton>
                    </LocalizationProvider>
                </div>

                <FormAutocomplete
                    autoHighlight
                    disableClearable
                    options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                    getOptionLabel={(option) => option.split(' ')[0]}
                    control={control}
                    name='timezone'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Timezone"
                            size='small'
                            color="success"
                            error={!!errors.timezone}
                            helperText={errors.timezone?.message}
                            sx={{
                                minWidth: '170px',
                            }}
                        />
                    )}
                />

                <FormControlLabel
                    label='Filter Helper'
                    control={
                        <Switch
                            checked={filterHelper}
                            onMouseDown={handleToggleFilterHelper}
                        />
                    }
                />

            </Stack>
        </Box>
    )
}