import { IconButton, Stack, TextField, } from "@mui/material"
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useCallback, } from "react";
import dayjs from "dayjs";
import FormAutocomplete from "../../../FormControls/FormAutocomplete";
import FormDatePicker from "../../../FormControls/FormDatePicker";
import { debounce } from "lodash";
import { useTaggingContext } from "../TaggingContext";
import { useFormContext } from "react-hook-form";

export const ViewForm = ({ onSubmit, formId, expanded }) => {
    const { pseOptions } = useTaggingContext();
    const { control, handleSubmit, getValues, setValue, formState: { errors, }, } = useFormContext();

    const handleDateUpdate = useCallback(debounce((key, value) => {
        //make sure start date is before stop date
        const start = getValues('startDate');
        const stop = getValues('stopDate');
        if ((key === 'startDate' && dayjs(stop).isBefore(value)) || (key === 'stopDate' && dayjs(start).isAfter(value))) {
            setValue('startDate', value);
            setValue('stopDate', value);
        } else {
            setValue(key, value);
        }
    }, 400), [setValue, getValues]);

    function handleIncrementDates(days) {
        const start = getValues('startDate');
        const stop = getValues('stopDate');
        setValue('startDate', start.add(days, 'day'));
        setValue('stopDate', stop.add(days, 'day'));
    }

    return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <Stack direction='row' spacing={2} alignItems='stretch' sx={{ p: 1, display: 'flex', flexWrap: 'wrap', rowGap: '16px', pt: 2 }}>
                <div style={{ display: 'flex', alignItems: 'start', height: '100%' }}>
                    <IconButton size='small' onMouseDown={() => {
                        handleIncrementDates(-1);
                    }}>
                        <ArrowLeftIcon />
                    </IconButton>
                    <FormDatePicker
                        name='startDate'
                        control={control}
                        label="Flow Date Start"
                        onChange={(newValue) => handleDateUpdate('startDate', newValue?.startOf('date'))}
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!errors.startDate,
                                helperText: expanded && (errors.startDate?.message ?? `Time Zone: ${getValues('timezone')?.split(' ')[0]}`),
                                sx: { minWidth: 150, maxWidth: 150, }
                            }
                        }}
                    />&nbsp;
                    <FormDatePicker
                        name='stopDate'
                        control={control}
                        label="Flow Date End"
                        onChange={(newValue) => handleDateUpdate('stopDate', newValue?.startOf('date'))}
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!errors.stopDate,
                                helperText: errors.stopDate?.message,
                                sx: { minWidth: 150, maxWidth: 150, }
                            }
                        }}
                    />
                    <IconButton size='small' onMouseDown={() => {
                        handleIncrementDates(1);
                    }}>
                        <ArrowRightIcon />
                    </IconButton>
                </div>

                {/* <FormAutocomplete
                    autoHighlight
                    options={sourceOptions}
                    control={control}
                    name='source'
                    slotProps={{
                        paper: {
                            sx: {
                                width: 'fit-content',
                            }
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Source"
                            size='small'
                            color="success"
                            error={!!errors.source}
                            helperText={errors.source?.message}
                            sx={{ minWidth: 200, }}
                        />
                    )}
                /> */}

                <FormAutocomplete
                    autoHighlight
                    options={pseOptions}
                    control={control}
                    name='excludeMarketPathPSE'
                    slotProps={{
                        paper: {
                            sx: {
                                width: 'fit-content',
                            }
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Exclude Market Path PSE"
                            size='small'
                            color="success"
                            sx={{ minWidth: 260, }}
                            error={!!errors.excludeMarketPathPSE}
                            helperText={errors.excludeMarketPathPSE?.message}
                        />
                    )}
                />
            </Stack>
        </form>
    );
}