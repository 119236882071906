import { useRef, useMemo, useState, useCallback, forwardRef, useImperativeHandle, useEffect, } from "react"
import { Box } from "@mui/material"
import dayjs from "dayjs"
import { useApi } from "../../useApi"
import { useDashboardFetch } from "../useDashboardFetch"
import { DealPositionRibbon } from "./Ribbon/DealPositionRibbon"
import { ViewContextProvider } from "../ViewContextProvider"
import * as yup from 'yup';
import { useLayoutFunctions } from "../../useLayoutFunctions"
import { DealPositionGrid } from "./DealPositionGrid"

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    date: yup.date().required('Date is required'),
});

export const DealPositionView = forwardRef(({ view, }, ref) => {
    const layoutStorageKey = `deal-rizz-position-report-grid-layout-${view.id}`;
    const gridRef = useRef();
    const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
    const { get, headers, logAction, enqueueSnackbar } = useApi();
    const [selectedRow, setSelectedRow] = useState();
    const toolbarFormId = `deal-rizz-position-report-toolbar-${view.id}`;
    const { captureLayout } = useLayoutFunctions();
    const [hourlyColumnVisibility, setHourlyColumnVisibility] = useState({
        showMWColumns: view.showMWColumns ?? true,
        showPriceColumns: view.showPriceColumns ?? false,
    });

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        ...view,
        date: dayjs(),
        showMWColumns: view.showMWColumns ?? true,
        showPriceColumns: view.showPriceColumns ?? false,
    }), [view]);

    useEffect(() => {
        setHourlyColumnVisibility({
            showMWColumns: defaults.showMWColumns,
            showPriceColumns: defaults.showPriceColumns,
        });
    }, [view]);

    const getRowId = useCallback(({ data }) => data.dealID, []);

    const dataFormatter = useCallback((data) => ({
        userGuid: headers.userGuid,
        date: dayjs(data.date).format('MM/DD/YYYY'),
        timezone: data.timezone,
    }), [headers]);

    const { loadData, applyData, silentUpdate, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_fetchDealPositionReport_v3',
        fetchParamKeys: ['userGuid', 'date', 'timezone'],
        gridRef,
        getRowId,
        dataFormatter,
    });

    const handleFetchData = useCallback(async (data) => {
        logAction('User fetched Position Report Data', 'Position Report', data);
        setDate(data.date);
        loadData(data).then((response) => {
            if (response?.data) {
                const formatted = response.data.map(row => {
                    const profile = JSON.parse(row.profileJson ?? '[]').reduce((acc, next) => {
                        const MW = (!!next.MW || next.MW === 0) ? parseFloat(parseFloat(next.MW).toFixed()) : null;
                        const Price = (!!next.price || next.price === 0) ? parseFloat(parseFloat(next.price).toFixed(2)) : null;
                        acc[next.HourOfDay] = {
                            MW,
                            Price,
                        };
                        return acc;
                    }, {});
                    return {
                        ...row,
                        ...profile,
                    };
                });
                applyData({
                    data: formatted,
                });
            }
        });
    }, [headers]);


    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box sx={{ height: '87vh', width: '99%', display: 'flex', flexDirection: 'column' }}>
                <DealPositionRibbon
                    toolbarFormId={toolbarFormId}
                    handleFetchData={handleFetchData}
                    gridRef={gridRef}
                    selectedRow={selectedRow}
                    setHourlyColumnVisibility={setHourlyColumnVisibility}
                />
                <DealPositionGrid
                    setSelectedRow={setSelectedRow}
                    getRowId={getRowId}
                    layoutStorageKey={layoutStorageKey}
                    ref={gridRef}
                    hourlyColumnVisibility={hourlyColumnVisibility}
                    date={date}
                    silentUpdate={silentUpdate}
                />
            </Box>
        </ViewContextProvider>
    )
});