import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { Box } from "@mui/material";
import { RefreshButton } from '../../RibbonButtons';
import { RibbonButton } from '../../Ribbon';

export const GridActionsGroup = ({ formId, expanded, handleFetchData, gridRef }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'start', p: 1, }}>
            <RefreshButton
                form={formId}
                expanded={expanded}
                onRefresh={handleFetchData}
            />

            <RibbonButton
                onMouseDown={() => {
                    gridRef.current?.api.deselectAll();
                }}
                icon={<RemoveDoneIcon fontSize='small' />}
                label={'Clear Selections'}
                expanded={expanded}
            />
        </Box>
    )
}