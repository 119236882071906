import { Stack } from "@mui/material"
import { RibbonButton } from "../../Ribbon"
import { RefreshButton } from "../../RibbonButtons"
import BarChartIcon from '@mui/icons-material/BarChart';

export const GridActionsGroup = ({ formId, expanded, setShowChart, showChart, handleRefresh }) => {
    return (
        <Stack direction='row' spacing={1} sx={{ p: 1, display: 'flex', flexWrap: 'wrap', rowGap: '16px', alignItems: 'start', flexDirection: 'row' }}>
            <RefreshButton
                form={formId}
                expanded={expanded}
                onRefresh={handleRefresh}
            />

            <RibbonButton
                onMouseDown={() => setShowChart(!showChart)}
                icon={<BarChartIcon fontSize='small' />}
                disabled
                label={showChart ? 'Hide Chart' : 'Show Chart'}
                expanded={expanded}
            />
        </Stack>
    )
}