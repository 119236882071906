import { useMemo, useCallback, forwardRef } from "react"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import dayjs from "dayjs"
import { CheckboxCellRenderer } from '../../AgGrid/CheckboxCellRenderer';
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { tooltipRenderer, redPastMarkerFormatter } from "../Utils"
import { jsonOrCommaSeparatedFormatter } from "../Utils"
import { useApi } from "../../useApi"
import { useDashboardLayout } from "../useDashboardLayout"
import useHubObject from "../../HubContext/useHubObject"
import { useUserInfo } from "../../UserInfoContext"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { useFormContext } from "react-hook-form"

export const DealSummaryGrid = forwardRef(({ setSelectedRow, getRowId, layoutStorageKey, silentUpdate, }, ref) => {
    const { getValues } = useFormContext();
    const { layout, } = getValues();
    const { columnTypes } = useColumnTypes();
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(ref);
    const { headers, enqueueSnackbar, } = useApi();
    const userInfo = useUserInfo();

    const baseColDefs = useMemo(() => ([
        {
            field: 'dealID',
            headerName: 'Deal ID',
            checkboxSelection: true,
        },
        {
            field: "confirmed",
            headerName: "Confirmed",
            cellRenderer: 'checkboxCellRenderer',
        },
        {
            field: 'Accounting',
        },
        {
            field: 'Book',
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        {
            field: 'Broker',
        },
        {
            field: 'Broker_Cost',
            headerName: 'Broker Cost',
        },
        {
            field: 'Broker_Price',
            headerName: 'Broker Price',
        },
        {
            field: 'Broker_Type',
            headerName: 'Broker Type',
        },
        {
            field: 'Buying_Trader',
            headerName: 'Buying Trader',
        },
        {
            field: 'Component',
        },
        {
            field: 'Component_2',
            headerName: 'Component 2',
        },
        {
            field: 'Component_Off',
            headerName: 'Component Off',
        },
        {
            field: 'Confirmation',
        },
        {
            field: 'Contract',
        },
        {
            field: 'Counterparty',
        },
        {
            headerName: "Deal Profile",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data.sparklineJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.capacityRequested ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.capacityRequested ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        {
            field: 'Creation_Time_MPT',
            headerName: 'Creation Time MPT',
            type: 'dateColumn',
        },
        {
            field: 'Deal_Currency',
            headerName: 'Deal Currency',
        },
        {
            field: 'Deal_Locked',
            headerName: 'Deal Locked',
        },
        {
            field: 'Deal_Number',
            headerName: 'Deal Name',
        },
        {
            field: 'Deal_Trait_1',
            headerName: 'Deal Trait 1',
        },
        {
            field: 'Deal_Trait_2',
            headerName: 'Deal Trait 2',
        },
        {
            field: 'Deal_Type',
            headerName: 'Deal Type',
        },
        {
            field: 'Designated_Parent_Number',
            headerName: 'Designated Parent #',
        },
        {
            field: 'Due_Date',
            headerName: 'Due Date',
        },
        {
            field: 'End_Date',
            headerName: 'End Date',
            type: 'dateColumn',
        },
        {
            field: 'Executing_Trader',
            headerName: 'Executing Trader',
        },
        {
            field: 'Exercise_Date',
            headerName: 'Exercise Date',
            type: 'dateColumn',
        },
        {
            field: 'Exercised_From',
            headerName: 'Exercised From',
        },
        {
            field: 'Expiration_Time_MPT',
            headerName: 'Expiration Time MPT',
            type: 'dateColumn',
        },
        {
            field: 'Expired_By',
            headerName: 'Expired By',
        },
        {
            field: 'Export_Time_MPT',
            headerName: 'Export Time MPT',
            type: 'dateColumn',
        },
        {
            field: 'Export_User',
            headerName: 'Export User',
        },
        {
            field: 'External_Key',
            headerName: 'External Key',
        },
        {
            field: 'FERC_Form_552',
            headerName: 'FERC Form 552',
        },
        {
            field: 'Financial_Type',
            headerName: 'Firm/Non-Firm',
        },
        {
            field: 'Formula',
        },
        {
            field: 'Formula_2',
            headerName: 'Formula 2',
        },
        {
            field: 'Formula_Off',
            headerName: 'Formula Off',
        },
        {
            field: 'Include_In_Confirmation',
            headerName: 'Include In Confirmation',
        },
        {
            field: 'por',
            headerName: 'POR',
        },
        {
            field: 'pod',
            headerName: 'POD',
        },
        {
            field: 'Zone',
            headerName: 'Zone'
        },
        {
            field: 'index_name',
            headerName: 'Index',
        },
        {
            field: 'Index_2',
            headerName: 'Index 2',
        },
        {
            field: 'Index_Off',
            headerName: 'Index Off',
        },
        {
            field: 'Internal',
            headerName: 'Comments',
        },
        {
            field: 'Market',
        },
        {
            field: 'Market_Type',
            headerName: 'Market Type',
        },
        {
            field: 'Market_Type_2',
            headerName: 'Market Type 2',
        },
        {
            field: 'Market_Type_Off',
            headerName: 'Market Type Off',
        },
        {
            field: 'Max_Price',
            headerName: 'Max Price',
        },
        {
            field: 'Min_Price',
            headerName: 'Min Price',
        },
        {
            field: 'Misc',
        },
        {
            field: 'Modification_Time_MPT',
            headerName: 'Modification Time MPT',
            type: 'dateColumn',
        },
        {
            field: 'Modification_User',
            headerName: 'Modification User',
        },
        {
            field: 'Multi_Location_Component_2',
            headerName: 'Multi Location Component 2',
        },
        {
            field: 'Multi_Location_Index_2',
            headerName: 'Multi Location Index 2',
        },
        {
            field: 'Multi_Location_Market_2',
            headerName: 'Multi Location Market 2',
        },
        {
            field: 'Multi_Location_Market_Type_2',
            headerName: 'Multi Location Market Type 2',
        },
        {
            field: 'Multi_Location_Pricing_Type_2',
            headerName: 'Multi Location Pricing Type 2',
        },
        {
            field: 'Multi_Location_Zone_2',
            headerName: 'Multi Location Zone 2',
        },
        {
            field: 'MW',
        },
        {
            field: 'MWOff',
            headerName: 'MW Off',
        },
        {
            field: 'Option',
        },
        {
            field: 'Option_Type',
        },
        {
            field: 'Price',
        },
        {
            field: 'PriceOff',
            headerName: 'Price Off',
        },
        {
            field: 'Product',
        },
        {
            field: 'Purch_Sales_FERC_552',
            headerName: 'Purch Sales FERC 552',
        },
        {
            field: 'Ramp',
        },
        {
            field: 'Resource_Type',
            headerName: 'Resource Type',
        },
        {
            field: 'Scheduling_Status',
            headerName: 'Scheduling Status',
        },
        {
            field: 'Seller',
        },
        {
            field: 'Selling_Trader',
            headerName: 'Selling Trader',
        },
        {
            field: 'Sleeve_Cost',
            headerName: 'Sleeve Cost',
        },
        {
            field: 'Sleeve_Price',
            headerName: 'Sleeve Price',
        },
        {
            field: 'Sleeve_Price_Type',
            headerName: 'Sleeve Price Type',
        },
        {
            field: 'Start_Date',
            headerName: 'Start Date',
            type: 'dateColumn',
        },
        {
            field: 'Status',
        },
        {
            field: 'Strategy',
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        {
            field: 'Strategy_2',
            headerName: 'Strategy 2',
        },
        {
            field: 'Strike_Price',
            headerName: 'Strike Price',
        },
        {
            field: 'Tax',
        },
        {
            field: 'originalTerm',
            headerName: 'Term'
        },
        {
            field: 'indexType',
            headerName: 'Index Type',
        },
        {
            field: 'dynamic',
            headerName: 'Dynamic',
        },
        {
            field: 'forecast',
            headerName: 'Forecast', //test
        },
        {
            field: 'Time_Zone',
            headerName: 'Time Zone',
        },
        {
            field: "Total_MWh",
            headerName: "Total MWh",
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined && !isNaN(value)) {
                    // Format value with commas and up to 3 decimal places
                    return parseFloat(value).toLocaleString('en-US', { 
                        minimumFractionDigits: 0, 
                        maximumFractionDigits: 3 
                    });
                }
                return value;
            }
        },        
        {
            field: "Total_Settlement",
            initialWidth: "150px",
            headerName: "Total Settlement",
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                }
                return value;
            }
        },
        {
            field: 'Trade_Date',
            headerName: 'Trade Date',
            type: 'dateColumn',
        },
        {
            field: 'Trader',
        },
        {
            field: 'Trading_Violation',
            headerName: 'Trading Violation',
        },
        {
            field: 'Transaction_Type',
            headerName: 'Transaction Type',
        },
        {
            field: 'Type_F_P',
            headerName: 'Type F P',
        },
        {
            field: 'Type_of_Transaction_for_FERC_552',
            headerName: 'Type of Transaction for FERC 552',
        },
        {
            field: 'Unit1_Name',
            headerName: 'Unit 1 Name',
        },
        {
            field: 'Unti1_Pct',
            headerName: 'Unit 1 Pct',
        },
        {
            field: 'Unit1_Plant',
            headerName: 'Unit 1 Plant',
        },
        {
            field: 'Unit2_SSE',
            headerName: 'Unit 2 SSE',
        },
        {
            field: 'Unit1_Zone',
            headerName: 'Unit 1 Zone',
        },
        {
            field: 'Unit2_Name',
            headerName: 'Unit 2 Name',
        },
        {
            field: 'Unit2_Pct',
            headerName: 'Unit 2 Pct',
        },
        {
            field: 'Unit2_Plant',
            headerName: 'Unit 2 Plant',
        },
        {
            field: 'Unit2_SSE',
            headerName: 'Unit 2 SSE',
        },
        {
            field: 'Unit2_Zone',
            headerName: 'Unit 2 Zone',
        },
        {
            field: 'Voiding_Time_MPT',
            headerName: 'Voiding Time MPT',
            type: 'dateColumn',
        },
        {
            field: 'Voiding_User',
            headerName: 'Voiding User',
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 1,
        //enableCellChangeFlash: true,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: { layout, },
        baseColDefs,
        defaultColDef,
    });

    const silentRefresh = useCallback(async () => {
        enqueueSnackbar('Deal changes detected. Updating...', { variant: 'info' });
        return silentUpdate(getValues());
    }, [getValues]);

    useHubObject({
        action: silentRefresh,
        allowedMessages: ['dealUpdate'],
        callbackDependencies: [headers],
        predicate: (obj) => {
            return (obj.tenantID.toString() === userInfo.tenantId?.toString())
                && (obj.userName !== userInfo.userName); //ignore own updates
        },
        debounceOptions: { maxWait: 1000, leading: true, },
        wait: 500,
    });

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    function onGridReady(params) {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const handleSelectionChanged = useCallback(() => {
        const selected = ref.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);

    /*const handleCellValueChanged = useCallback((params) => {
        params.api.flashCells({
            rowNodes: [params.node],
            columns: [params.column],
            flashDelay: 1000,
            fadeDelay: 2000,
        });
    }, []);*/

    /*const handleRowDataUpdated = useCallback(() => {
        if (newRows.current?.length && loaded) {
            const nodes = newRows.current.map(id => params.api.getRowNode(id)).filter(node => node);
            params.api.flashCells({
                rowNodes: nodes,
                flashDelay: 1000,
                fadeDelay: 2000,
            });
            newRows.current = undefined;
        }
    }, []);*/

    return (
        <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                getRowId={getRowId}
                ref={ref}
                enableCharts
                columnDefs={styledColDefs}
                //onCellValueChanged={handleCellValueChanged}
                //onRowDataUpdated={handleRowDataUpdated}
                onFirstDataRendered={handleFirstDataRendered}
                onCellMouseOver={handleCellMouseOverHighlight}
                rowMultiSelectWithClick
                rowSelection="single"
                onSelectionChanged={handleSelectionChanged}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                statusBar={defaultStatusBar}
                sideBar={sideBar}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    checkboxCellRenderer: CheckboxCellRenderer,
                }}
            />
        </AgGridContainer>
    )
});