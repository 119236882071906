import { forwardRef, useRef, useMemo, useCallback, useImperativeHandle, useState, useEffect } from "react"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { columnPanel, filterPanel, palettePanel } from "../../ToolPanels/DefaultToolPanels"
import useGridLayout from "../../useGridLayout"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { heatmapStyle, handleUpdateTotalRows, tooltipRenderer, redPastMarkerFormatter } from "../Utils"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import dayjs from "dayjs"
import { jsonOrCommaSeparatedFormatter } from "../Utils"
import isEqual from 'lodash/isEqual'

export const UnscheduledMWsGrid = forwardRef(({ data, date, setSelectedRows, selectedRows, }, ref) => {
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const paletteRef = useRef({ showHeatmap: false });
    const layoutStorageKey = `deal-rizz-scheduling-unscheduled-mws-grid-layout`;

    const baseColDefs = useMemo(() => ([
        {
            headerName: 'Deal ID',
            checkboxSelection: true,
            field: 'dealID',
            cellDataType: 'text',
        },
        {
            field: 'Date',
            type: 'dateColumn',
        },
        {
            field: 'Deal_Token',
            headerName: 'Deal Name',
        },
        {
            field: 'Status',
            headerName: 'Deal Status',
        },
        {
            field: 'Book',
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        {
            field: 'Counterparty',
        },
        {
            field: 'Contract',
        },
        {
            headerName: 'Firm/Non-Firm',
            field: 'FirmNonFirm',
        },
        {
            field: 'Index',
        },
        {
            field: 'Creator',
        },
        {
            field: 'Market',
        },
        {
            field: 'Zone',
            headerName: 'Zone',
        },
        {
            field: 'Hidden',
            headerName: 'Hidden',
        },
        {
            field: 'por',
            headerName: 'POR',
        },
        {
            field: 'pod',
            headerName: 'POD',
        },
        {
            field: 'Strategy',
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        {
            field: 'Term',
        },
        {
            headerName: "Full Deal Profile",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            initialHide: true,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data.dealProfileJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        {
            headerName: "Full Price Curve",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            initialHide: true,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data.dealProfileJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.price ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.price ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        {
            headerName: 'Deal Position',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            flex: 3,
            minWidth: 200,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                return [...Array.from({ length: 26 }).keys()].reduce((acc, next) => {
                    const hour = next + 1;

                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour] ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour] ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            flex: 1,
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 55,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            cellStyle: params => heatmapStyle(params, paletteRef),
        })),
        {
            field: 'Deal_Currency',
            headerName: 'Deal Currency',
        },
        {
            field: 'Total_Settlement',
            headerName: 'Total Settlement',
        },
        {
            field: 'Transaction_Type',
            headerName: 'Transaction Type',
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 2,
    }), []);

    const { colDefs, layoutPanel, loadLayout, captureLayout, applySavedFilters } = useGridLayout(layoutStorageKey, gridRef, baseColDefs, defaultColDef);

    useImperativeHandle(ref, () => {
        return {
            captureLayout,
            clearSelected: () => gridRef.current?.api.deselectAll(),
            showLoadingOverlay: () => gridRef.current?.api?.showLoadingOverlay(),
            hideOverlay: () => gridRef.current?.api?.hideOverlay(),
        };
    });

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(gridRef, paletteRef, 'deal-rizz-unscheduled-mws-palette'),
        ]
    }), []);

    function onGridReady(params) {
        loadLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applySavedFilters();
    }, []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, colDefs[0]?.field);
    }, [colDefs]);

    function handleSelectionChanged() {
        if (setSelectedRows) {
            const selectedRows = gridRef.current.api.getSelectedRows();
            setSelectedRows(selectedRows);
        }
    }

    function handleRowDataUpdated(params) {
        updateTotalRows(params);
        const currentSelection = gridRef.current.api.getSelectedRows();
        if (!isEqual(currentSelection, selectedRows)) {
            selectedRows.forEach(row => {
                const node = gridRef.current.api.getRowNode(row.dealID);
                if (node) {
                    node.setSelected(true);
                }
            });
        }
    }

    const getRowId = useCallback((params) => {
        const { data, } = params;
        return `${data?.dealID}`;
    }, []);

    return (
        <AgGridReact
            {...defaultGridOptions}
            containerStyle={{ height: '100%', width: '100%' }}
            rowData={data}
            getRowId={getRowId}
            ref={gridRef}
            gridOptions={gridOptions}
            onFilterChanged={updateTotalRows}
            onRowDataUpdated={handleRowDataUpdated}
            onSelectionChanged={handleSelectionChanged}
            rowSelection='multiple'
            rowMultiSelectWithClick
            overlayNoRowsTemplate="No rows. Change the start date in the ribbon to a time with deals."
            enableCharts
            columnDefs={colDefs}
            onFirstDataRendered={handleFirstDataRendered}
            onGridReady={onGridReady}
            columnTypes={columnTypes}
            sideBar={sideBar}
            components={{
                layoutToolPanel: LayoutToolPanel,
                paletteToolPanel: PaletteToolPanel,
            }}
        />
    );
});