import { AgChartsReact } from 'ag-charts-react';
import { useMemo, } from 'react';
import { useTheme } from '@mui/material';

export const PositionManagementChart = ({ data = [], }) => {
    const theme = useTheme();
    const palette = theme.palette.primary;

    function tooltipRenderer({ datum, xKey, yKey }) {
        const HE = datum[xKey];
        const MW = datum[yKey];
        return {
            content: `HE ${HE}: ${MW} MW`,
        };
    }

    const isDeal = (data) => !data?.scheduleID;

    function formatDataForChart(data) {
        //create a Map with the hour as key
        const dataMap = new Map(Array.from({ length: 26 }, (_, i) => i).map(i => [i + 1, { HE: i + 1, }]));

        //now for every deal, add the MW for each hour, and subtract the scheduled MW for each hour
        data.forEach(row => {
            if (isDeal(row)) {
                dataMap.keys().forEach(hour => {
                    dataMap.get(hour).DealTotal = ((dataMap.get(hour).DealTotal ?? 0) + (row[hour] ?? 0));
                });
            } else {
                dataMap.keys().forEach(hour => {
                    dataMap.get(hour).ScheduleTotal = ((dataMap.get(hour).ScheduleTotal ?? 0) + (row[hour] ?? 0));
                });
            }
        });

        const dataArr = Array.from(dataMap.values());
        const formattedData = dataArr.map(row => {
            const dealTotal = row.DealTotal ?? 0;
            const scheduleTotal = row.ScheduleTotal ?? 0;
            const overscheduled = Math.abs(Math.min(dealTotal - scheduleTotal, 0));
            return {
                HE: row.HE,
                Scheduled: scheduleTotal - overscheduled,
                Original: Math.max(dealTotal - scheduleTotal, 0),
                Overscheduled: overscheduled,
            }
        });

        return formattedData;
    }

    const chartOptions = useMemo(() => ({
        theme: {
            baseTheme: theme.palette.mode === 'light' ? 'ag-material' : 'ag-material-dark',
            palette: {
                fills: [
                    palette.green,
                    palette.main,
                    palette.red,
                ],
                strokes: [
                    palette.green,
                    palette.main,
                    palette.red,
                ],
            },
            overrides: {
                line: { series: { strokeWidth: 3, marker: { enabled: true } } },
            },
        },
        background: {
            fill: theme.palette.mode === 'dark' ? palette.darkBackground : palette.white,
        },
        autoSize: true,
        padding: {
            left: 40,
            right: 40,
        },
        axes: [
            {
                position: 'bottom',
                type: 'number',
                nice: false,
                label: {
                    formatter: ({ value, }) => `HE ${value}`,
                },
            },
            {
                position: 'left',
                type: 'number',
                min: 0,
                title: {
                    text: 'MW',
                },
            },
        ],
        legend: {
            position: 'right',
        },
        data: formatDataForChart(data),
        series: [
            {
                yKey: 'Scheduled',
                yName: 'Scheduled',
                type: 'bar',
                xKey: 'HE',
                stacked: true,
                tooltip: { renderer: tooltipRenderer, },
            },
            {
                yKey: 'Original',
                yName: 'Original',
                type: 'bar',
                xKey: 'HE',
                stacked: true,
                tooltip: { renderer: tooltipRenderer, },
            },
            {
                yKey: 'Overscheduled',
                yName: 'Overscheduled',
                type: 'bar',
                xKey: 'HE',
                stacked: true,
                tooltip: { renderer: tooltipRenderer, },
            },
        ],
    }), [data, theme.palette.mode]);

    return (
        <AgChartsReact options={chartOptions} />
    );
}