import React, { useEffect, useState } from 'react';

import axios from 'axios';
import DivGuard from '../Guards/DivGuard';
import { userGroups } from '../../authConfig';
import { useUserGroups } from '../../data/useUserGroups';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';

const AISupportTickets = (props) => {

  const [openDialog, setOpenDialog] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [approvedResponse, setApprovedResponse] = useState('');
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);


  const handleOpenDialog = (email) => {
    setCurrentEmail(email);
    setApprovedResponse(email.aiResponse);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentEmail(null);
    setApprovedResponse('');
  };

  const handleSendApprovedResponse = async () => {
    const { from, subject } = currentEmail;

    try {
      await axios.post('/api/support/send-email', {
        toAddress: from,
        subject,
        message: approvedResponse,
      });
      alert('Email sent successfully!');
      // Remove the email from the list
      setEmails(emails.filter((e) => e.uid !== currentEmail.uid));
      handleCloseDialog();
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }
  };

  return (
    <DivGuard groups={[userGroups.admins]}>
      <div>
        <h1>Support Admin</h1>
        {loading ? (
          <p>Loading emails...</p>
        ) : emails.length > 0 ? (
          emails.map((email) => (
            <div
              key={email.uid}
              style={{
                border: '1px solid #ccc',
                padding: '10px',
                marginBottom: '10px',
              }}
            >
              <p>
                <strong>From:</strong> {email.from}
              </p>
              <p>
                <strong>Subject:</strong> {email.subject}
              </p>
              <p>
                <strong>Body:</strong> {email.body}
              </p>
              <p>
                <strong>AI Suggested Response:</strong> {email.aiResponse}
              </p>
              <Button variant="contained" onClick={() => handleOpenDialog(email)}>
                Approve and Send
              </Button>
            </div>
          ))
        ) : (
          <p>No new emails.</p>
        )}

        {/* Dialog for editing and approving the response */}
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <div style={{ padding: '20px' }}>
            <h2>Edit Response</h2>
            <TextField
              label="Response"
              multiline
              rows={10}
              fullWidth
              variant="outlined"
              value={approvedResponse}
              onChange={(e) => setApprovedResponse(e.target.value)}
            />
            <div style={{ marginTop: '20px', textAlign: 'right' }}>
              <Button onClick={handleCloseDialog} style={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleSendApprovedResponse}>
                Send
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
   </DivGuard>
  );
};


export default AISupportTickets;
