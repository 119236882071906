import { DealActionsGroup } from "./DealActionsGroup";
import { ScheduleActionsGroup } from "./ScheduleActionsGroup";
import { GridActionsGroup } from "./GridActionsGroup";
import { ViewForm } from "./ViewForm";
import { Ribbon } from "../../Ribbon";

export const PositionManagementRibbon = ({ toolbarFormId, selectedRow, gridRef, handleFetchData, }) => {
    

    return (
        <Ribbon>
            {[
                {
                    title: 'Deals',
                    content: <DealActionsGroup
                        formId={toolbarFormId}
                        selectedRow={selectedRow}
                    />
                },
                {
                    title: 'Schedules',
                    content: <ScheduleActionsGroup
                        formId={toolbarFormId}
                        selectedSchedule={selectedRow}
                        gridRef={gridRef}
                        handleFetchData={handleFetchData}
                    />
                },
                {
                    title: 'Grid',
                    content: <GridActionsGroup
                        formId={toolbarFormId}
                        gridRef={gridRef}
                        handleFetchData={handleFetchData}
                    />
                },
                {
                    title: 'Filter',
                    content: <ViewForm
                        onSubmit={handleFetchData}
                        formId={toolbarFormId}
                    />
                },
            ]}
        </Ribbon>
    );
}