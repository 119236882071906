import { Ribbon } from "../../Ribbon";
import { ActionsGroup } from "./ActionsGroup";
import { ViewForm } from "./ViewForm";

export const SchedulingRibbon = ({ formId, selectedRows, sourceSinkOptions, onSubmit, }) => {
    return (
        <Ribbon>
            {[
                {
                    title: 'Actions',
                    content: <ActionsGroup
                        formId={formId}
                        selectedRows={selectedRows}
                        sourceSinkOptions={sourceSinkOptions}
                        scheduleFormId={'scheduling-create-schedule-form'}
                        handleFetchData={onSubmit}
                    />
                },
                {
                    title: 'Filters',
                    content: <ViewForm
                        formId={formId}
                        onSubmit={onSubmit}
                    />
                },
            ]}
        </Ribbon>
    );
}