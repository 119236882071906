import { Ribbon } from "../../Ribbon";
import { ViewForm } from "./ViewForm";
import { ScheduleActionsGroup } from "./ScheduleActionsGroup";
import { DealActionsGroup } from "./DealActionsGroup";
import { GridActionsGroup } from "./GridActionsGroup";

export const ScheduleSummaryRibbon = ({ selectedRow, handleRowConfirmUpdate, handleRowVoidUpdate, toolbarFormId, selectedDeal, handleFetchData }) => {
    return (
        <Ribbon>
            {[
                {
                    title: 'Schedules',
                    content: <ScheduleActionsGroup
                        selectedSchedule={selectedRow}
                        handleRowVoidUpdate={handleRowVoidUpdate}
                        handleRowConfirmUpdate={handleRowConfirmUpdate}
                    />
                },
                {
                    title: 'Deals',
                    content: <DealActionsGroup
                        formId={toolbarFormId}
                        selectedRow={selectedDeal}
                    />
                },
                {
                    title: 'Grid',
                    content: <GridActionsGroup
                        formId={toolbarFormId}
                        handleFetchData={handleFetchData}
                    />
                },
                {
                    title: 'Filters',
                    content: <ViewForm
                        formId={toolbarFormId}
                        onSubmit={handleFetchData}
                    />
                },
            ]}
        </Ribbon>
    );
}