import { Box } from "@mui/material";
import { EditDealButton } from "../../RibbonButtons";
import { useFormContext } from "react-hook-form";

export const DealActionsGroup = ({ expanded, formId, selectedRow, }) => {
    const { watch } = useFormContext();
    const timezone = watch('timezone');

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', p: 1, }}>
            <EditDealButton
                expanded={expanded}
                disabled={!selectedRow}
                deal={selectedRow}
                formId={formId}
                fetchDealData={true}
                timezone={timezone}
            />
        </Box>
    )
}