import useGridLayout from "../useGridLayout";

export const useDashboardLayout = ({ gridRef, layoutStorageKey, context = {}, baseColDefs, defaultColDef, }) => {
    const { colDefs, applyLayout: applyContextLayout, loadLayout, captureLayout, applyFilters: applyContextFilters, applySavedFilters, clearLayout, saveLayoutLocal, deleteLayoutLocal } = useGridLayout(layoutStorageKey, gridRef, baseColDefs, defaultColDef);

    const layoutPanel = {
        id: 'layout',
        labelDefault: 'Layout',
        labelKey: 'layout',
        toolPanel: 'layoutToolPanel',
        toolPanelParams: {
            onLayoutClear: resetLayout,
            onLayoutLoad: applyLayout,
            onLayoutSave: saveLayoutLocal,
            onLayoutDelete: deleteLayoutLocal
        },
        minWidth: 180,
        maxWidth: 400,
        width: 200
    }

    function resetLayout() {
        if (context?.layout) {
            applyContextLayout(context.layout);
        } else {
            clearLayout();
        }

        if (context?.layout?.filters) {
            applyContextFilters(context.layout.filters);
        }
    }

    function applyFilters() {
        if (context?.layout?.filters && !localStorage.getItem(layoutStorageKey)) {
            applyContextFilters(context.layout.filters);
        } else if (localStorage.getItem(layoutStorageKey)) {
            applySavedFilters();
        }
    }

    function applyLayout() {
        if (context?.layout && !localStorage.getItem(layoutStorageKey)) {
            applyContextLayout(context.layout);
        } else {
            loadLayout();
        }
    }

    return {
        colDefs,
        layoutPanel,
        applyLayout,
        applyFilters,
        captureLayout,
    }
}