import { RefreshButton } from "../../RibbonButtons"
import { Stack } from "@mui/material"
import { RibbonButton } from "../../Ribbon"
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';

export const GridActionsGroup = ({ formId, expanded, gridRef, handleFetchData }) => {
    return (
        <Stack direction='row' spacing={1} sx={{ p: 1, display: 'flex', flexWrap: 'wrap', rowGap: '16px', alignItems: 'start', flexDirection: 'row' }}>
            <RibbonButton
                onMouseDown={() => gridRef.current?.clearSelected()}
                icon={<RemoveDoneIcon fontSize='small' />}
                label={'Clear Selections'}
                expanded={expanded}
            />

            <RefreshButton
                form={formId}
                label={'Refresh All'}
                expanded={expanded}
                onRefresh={handleFetchData}
            />
        </Stack>
    )
}