import { RibbonButton } from "../Ribbon";
import { LookupValuesProvider } from "../DealEntry/LookupContext";
import { DealEntryDialog } from "../DealEntry/DealEntryDialog";
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import { useApi } from "../../useApi";

export const EditDealButton = ({ expanded, disabled, deal, formId, fetchDealData = false, timezone, ...buttonProps }) => {
    const [openDealEntryDialog, setOpenDealEntryDialog] = useState(false);
    const [dealToEdit, setDealToEdit] = useState(deal);
    const [loading, setLoading] = useState(false);
    const { get, headers, logAction, apiUrlPrefix } = useApi();

    async function fetchDealSummary() {
        const dealID = deal.dealID;
        setLoading(true);
        logAction('User fetched Deal Summary Data', 'Deal Summary', dealID);
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchDealSummaryByDealID_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${dealID}`
            + `&parm=${timezone}`

        return get(url).then(response => {
            response.data && setDealToEdit(response.data[0]);
        }).finally(() => {
            setLoading(false)
        });
    }

    return (
        <span>
            <LookupValuesProvider>
                <DealEntryDialog
                    open={openDealEntryDialog}
                    dealToEdit={dealToEdit}
                    handleClose={() => setOpenDealEntryDialog(false)}
                    handleSave={() => {
                        setOpenDealEntryDialog(false);
                        //programmatically click the refresh button to update the grid
                        document.getElementById(`${formId}-refresh`).click();
                    }}
                    loading={loading}
                />
            </LookupValuesProvider>
            <RibbonButton
                onMouseDown={() => {
                    if (fetchDealData) {
                        fetchDealSummary();
                    } else {
                        setDealToEdit(deal);
                    }
                    setOpenDealEntryDialog(true);
                }}
                icon={<EditIcon fontSize='small' />}
                label={'Edit'}
                disabled={disabled}
                expanded={expanded}
                {...buttonProps}
            />
        </span>
    )
}