import { RefreshButton } from "../../RibbonButtons";
import { Box } from "@mui/material";

export const GridActionsGroup = ({ formId, expanded, handleFetchData, }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'start', p: 1, }}>
            <RefreshButton
                formId={formId}
                expanded={expanded}
                onRefresh={handleFetchData}
            />
        </Box>
    );
}