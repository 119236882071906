import { useState, useEffect } from 'react';
import FormAutocomplete from '../../FormControls/FormAutocomplete';
import { FormControlLabel, TextField, Grid, Button, Badge, Tooltip, Box, } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';
import { useFormContext } from 'react-hook-form';
import { PercentageDropdown } from './PercentageDropdown';
import dayjs from 'dayjs';
import FormDatePicker from '../../FormControls/FormDatePicker';
import FormCheckbox from '../../FormControls/FormCheckbox';
import { useApi } from '../../useApi';

export const DealEntryForm = ({ setOpenProfileEditor, options }) => {
    const { formState: { errors, }, control, getValues, register, watch, setValue, } = useFormContext();
    const defaultBreakpoints = { xs: 10, sm: 5, md: 2, };
    const { headers, apiUrlPrefix, enqueueSnackbar, get } = useApi();
    const [traderOptions, setTraderOptions] = useState([]);

    useEffect(() => {
        fetchTraderList();
    }, []);

    function fetchTraderList() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserListFetch&parm=${headers.userGuid}`;

        get(url).then(response => {
            const names = [...new Set(response.data.map(user => user.userName).filter(name => name))]; //filter out falsey and duplicate values
            setTraderOptions(names);
        }).catch(error => {
            enqueueSnackbar(`Error fetching trader options. ${error.response?.data} Status: ${error.response?.status}. Message: ${error}`, { variant: 'error', });
        });
    }

    return (
        <Box key='trader-fields-form-group' sx={{ pt: 2, }}>
            <Grid container spacing={2} columns={10}>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        options={options['Transaction_Type'] ?? []}
                        control={control}
                        name="Transaction_Type"
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Transaction Type"
                                size='small'
                                error={!!errors.Transaction_Type}
                                helperText={errors.Transaction_Type?.message}
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        options={options['Transaction'] ?? []}
                        control={control}
                        name="Transaction"
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Transaction"
                                size='small'
                                error={!!errors.Transaction}
                                helperText={errors.Transaction?.message}
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        options={options['Counterparty'] ?? []}
                        control={control}
                        name="Counterparty"
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Counterparty"
                                size='small'
                                error={!!errors.Counterparty}
                                helperText={errors.Counterparty?.message}
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        options={options['Index'] ?? []}
                        control={control}
                        name="Index"
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Index"
                                size='small'
                                error={!!errors.Index}
                                helperText={errors.Index?.message}
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                <Grid item {...defaultBreakpoints}>
                    <Badge invisible={!getValues('profile')?.length && !errors.profile} sx={{ width: '100%' }} color={errors.profile ? 'error' : 'success'} badgeContent={
                        errors.profile ? <Tooltip title={errors.profile?.message} arrow placement="top"><ErrorOutline fontSize="small" /></Tooltip> : <Check fontSize="small" />
                    }>
                        <Button
                            variant="contained"
                            fullWidth
                            onMouseDown={() => setOpenProfileEditor(true)}
                        >
                            Set Profile
                        </Button>
                    </Badge>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <PercentageDropdown
                        name='Book'
                        lookupOptions={options}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Book"
                                error={!!errors.Book}
                                helperText={errors.Book?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <FormAutocomplete
                        name='Contract'
                        control={control}
                        options={options['Contract'] ?? []}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Contract"
                                error={!!errors.Contract}
                                helperText={errors.Contract?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                    <FormAutocomplete
                        name='Financial_Type'
                        control={control}
                        options={options['Financial_Type'] ?? []}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Firm/Non-Firm"
                                error={!!errors.Financial_Type}
                                helperText={errors.Financial_Type?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                    <FormAutocomplete
                        name='Market'
                        control={control}
                        options={options['Market'] ?? []}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Market"
                                error={!!errors.Market}
                                helperText={errors.Market?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                    <FormAutocomplete
                        name='Zone'
                        control={control}
                        options={options['Zone'] ?? []}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Zone"
                                error={!!errors.Zone}
                                helperText={errors.Zone?.message}
                            />
                        )}
                    />
                </Grid>

                
                <Grid item xs={12} sm={6} md={2}>
                    <FormAutocomplete
                        name='Trader'
                        control={control}
                        options={traderOptions}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Trader"
                                error={!!errors.Trader}
                                helperText={errors.Trader?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        {...register('dealToken')}
                        size='small'
                        fullWidth
                        label="Deal Name"
                        error={!!errors.dealToken}
                        helperText={errors.dealToken?.message}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <FormDatePicker
                        name='Trade_Date'
                        control={control}
                        disablePast
                        label="Trade Date"
                        format="MM/DD/YYYY"
                        views={['year', 'month', 'day',]}
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!errors.Trade_Date,
                                helperText: errors.Trade_Date?.message,
                                fullWidth: true,
                                onBlur: () => {
                                    const startOfHour = dayjs(watch('Trade_Date')).startOf('hour');
                                    setValue('Trade_Date', startOfHour);
                                },
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <PercentageDropdown
                        name='Strategy'
                        lookupOptions={options}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Strategy"
                                error={!!errors.Strategy}
                                helperText={errors.Strategy?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                    <FormAutocomplete
                        name='por'
                        control={control}
                        options={options['por'] ?? []}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="POR"
                                error={!!errors.por}
                                helperText={errors.por?.message}
                            />
                        )}
                    />
                </Grid>
                
                <Grid item xs={12} sm={6} md={2}>
                    <FormAutocomplete
                        name='pod'
                        control={control}
                        options={options['pod'] ?? []}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="POD"
                                error={!!errors.pod}
                                helperText={errors.pod?.message}
                            />
                        )}
                    />
                </Grid>


                <Grid item xs={12} sm={3} md={2}>
                    <FormControlLabel
                        control={<FormCheckbox
                            name={'confirmed'}
                            control={control}
                        />}
                        label="Confirmed"
                    />
                </Grid>
                
                <Grid item xs={10}>
                    <TextField
                        {...register('comments')}
                        id="comments"
                        multiline
                        fullWidth
                        label="Internal Comments"
                        maxRows={5}
                        error={!!errors.comments}
                        helperText={errors.comments?.message}
                    />
                </Grid>

            </Grid>
        </Box>
    );
};