import { useRef, useMemo, useState, useCallback, forwardRef, } from "react"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import dayjs from "dayjs"
import { jsonOrCommaSeparatedFormatter } from "../Utils"
import { heatmapStyle, tooltipRenderer, redPastMarkerFormatter } from "../Utils"
import { PaletteToolPanel } from "./PaletteToolPanel"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { useApi } from "../../useApi"
import { useDashboardLayout } from "../useDashboardLayout"
import useHubObject from "../../HubContext/useHubObject"
import useHubAction from "../../HubContext/useHubAction"
import { useUserInfo } from "../../UserInfoContext"
import { colorForValue } from "../Utils"
import { useFormContext } from "react-hook-form"

export const DealPositionGrid = forwardRef(({ setSelectedRow, date, getRowId, layoutStorageKey, silentUpdate, hourlyColumnVisibility, }, ref) => {
    const { columnTypes } = useColumnTypes();
    const paletteStorageKey = 'position-report-palette';
    const paletteRef = useRef({ showHeatmap: false });
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(ref);
    const { post, headers, apiUrlPrefix, enqueueSnackbar, } = useApi();
    
    const userInfo = useUserInfo();
    const { getValues } = useFormContext();
    const { layout, } = getValues();

    const heatmapStyle = (params, paletteRef, key) => {
        let styles = {};
        if (!!paletteRef.current?.[key] && !!params.value) {
            styles = {
                backgroundColor: colorForValue(params.value),
                textShadow: 'black 1px 1px 4px',
                color: 'white',
            }
        }
        return styles;
    };

    const handleCellValueChanged = useCallback(async (event) => {
        const updatedRow = event.data; // Get the updated row data
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_ModifyDeal`
            + `&parm=${headers.userGuid}`
            + `&parm=${updatedRow.dealID}` 
            + `&parm=${date}`
            + `&parm=${updatedRow.Time_Zone}`

        
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    ...headers, // Your API headers (use the existing headers from `useApi`)
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(event.data), // Send the updated row as JSON
            });

            // enqueueSnackbar(JSON.stringify(updatedRow));

            if (response.ok) {
                enqueueSnackbar("Row data updated successfully", { variant: "success" });
            } else {
                enqueueSnackbar("Failed to update row data", { variant: "error" });
            }
        } catch (error) {
            enqueueSnackbar("Error while updating row data", { variant: "error" });
        }
    }, [enqueueSnackbar, headers]);


    const baseColDefs = useMemo(() => ([
        { 
            field: 'dealID', 
            headerName: 'Deal ID', 
            cellDataType: 'text',
        },
        { field: 'Deal_Number', headerName: 'Deal Name', },
        { field: 'Deal_Currency', headerName: 'Deal Currency', },
        {
            field: 'Book',
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        { field: 'Contract', },
        { field: 'Status', },
        { field: 'Counterparty', },
        { field: 'Market', },
        { field: 'Transaction_Type', headerName: 'Transaction Type', },
        { field: 'index_name', headerName: 'Index', },
        { field: 'indexType', headerName: 'RT/DA', },
        {
            field: 'por',
            headerName: 'POR',
        },
        {
            field: 'pod',
            headerName: 'POD',
        },
        {
            headerName: 'Deal Position',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                return [...Array.from(Object.keys(data))].reduce((acc, hour) => {
                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour]?.MW ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour]?.MW ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        {
            headerName: 'Price Curve',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                return [...Array.from(Object.keys(data))].reduce((acc, hour) => {
                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour]?.Price ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour]?.Price ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        }, ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            minWidth: 20,
            initialWidth: 50,
            children: [
                { field: `${i + 1}.MW`, editable: false, headerName: 'MW', width: 60, cellStyle: params => heatmapStyle(params, paletteRef, 'showMWHeatmap'), aggFunc: 'sum', enableRowGroup: false, resizable: true, },
                { field: `${i + 1}.Price`, editable: false, headerName: '$', width: 60, cellStyle: params => heatmapStyle(params, paletteRef, 'showPriceHeatmap'), aggFunc: 'sum', enableRowGroup: false, resizable: true, },
            ],
            //enableRowGroup: false,
            //aggFunc: 'sum',
            //chartDataType: 'series',
            //cellStyle: params => heatmapStyle(params, paletteRef),
        })),
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: { layout, },
        baseColDefs,
        defaultColDef,
    });

    const silentRefresh = useCallback(async () => {
        enqueueSnackbar('Deal changes detected. Updating...', { variant: 'info' });
        return silentUpdate(getValues());
    }, [getValues]);

    useHubObject({
        action: silentRefresh,
        // allowedMessages: ['dealUpdate'],
        allowedMessages: ['dealDealUpdateXXXX'], //erik, I changed this so that auto refreshing stops until you figure out how to make the grid update without the MW field disappearing. 
        callbackDependencies: [headers],
        predicate: (obj) => {
            return (obj.tenantID.toString() === userInfo.tenantId?.toString())
                && (obj.userName !== userInfo.userName); //ignore own updates
        },
        debounceOptions: { maxWait: 1000, leading: true, },
        wait: 500,
    });


    const styledColDefs = useMemo(() => {
        const firstVisibleColumn = colDefs.find(colDef => !colDef.hide && !colDef.initialHide);
        //set checkbox selection to first visible column
        if (firstVisibleColumn) {
            firstVisibleColumn.checkboxSelection = true;
        }

        const visibleColumns = colDefs.map(colDef => {
            if (!colDef.children) return colDef;
            return {
                ...colDef,
                children: colDef.children.map(child => ({
                    ...child,
                    hide: (!hourlyColumnVisibility.showMWColumns && child.field.includes('.MW')) ||
                        (!hourlyColumnVisibility.showPriceColumns && child.field.includes('.Price')),
                })),
            }
        });

        return visibleColumns.map(colDef => ({
            ...colDef,
            cellClassRules: highlightingCellClassRules,
        }));
    }, [colDefs, highlightingCellClassRules, hourlyColumnVisibility]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(ref, paletteRef, paletteStorageKey),
        ]
    }), []);

    function onGridReady(params) {
        applyLayout();
    }

    const handleUpdateTotalRows = (api, totalColKey) => {
        const visibleRows = [];
        api.forEachNodeAfterFilterAndSort((node) => {
            visibleRows.push(node.data);
        });

        if (!visibleRows?.length) return [];
        const totalRows = [visibleRows.reduce((acc, next) => {
            //check if next is an object; may not be the case for group rows
            if (typeof next !== 'object') return acc;
            Array.from({ length: 26 }, (_, i) => i + 1).forEach(hour => {
                const mwSum = parseFloat(acc[hour]?.MW ?? 0) + parseFloat(next[hour]?.MW ?? 0);
                const priceSum = parseFloat(acc[hour]?.Price ?? 0) + parseFloat(next[hour]?.Price ?? 0);
                acc[hour] = {
                    MW: parseFloat(mwSum.toFixed(2)),
                    Price: parseFloat(priceSum.toFixed(2)),
                }
            });
            return acc;
        }, { [totalColKey]: 'Total' })]; //the totalColKey is the key for the column in which to display the word Total
        //api.setGridOption('pinnedBottomRowData', totalRows);
        api.setPinnedBottomRowData(totalRows);
    };

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, styledColDefs[0]?.field ?? 'dealID');
    }, [styledColDefs]);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
        // Add the onCellValueChanged event handler
        onCellValueChanged: handleCellValueChanged,
        // onCellValueChanged: (params) => handleCellValueChanged(params.data), // Pass the row data to the callback
    }), []);

    const handleSelectionChanged = useCallback(() => {
        const selected = ref.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);

    return (
        <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '99%', }}
                ref={ref}
                getRowId={getRowId}
                gridOptions={gridOptions}
                enableCharts
                columnDefs={styledColDefs}
                onSelectionChanged={handleSelectionChanged}
                rowSelection="single"
                rowMultiSelectWithClick
                onFilterChanged={updateTotalRows}
                onRowDataUpdated={updateTotalRows}
                onFirstDataRendered={handleFirstDataRendered}
                onCellMouseOver={handleCellMouseOverHighlight}
                groupTotalRow={"bottom"}
                suppressAggFuncInHeader={true}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                statusBar={defaultStatusBar}
                sideBar={sideBar}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    paletteToolPanel: PaletteToolPanel,
                }}
            />
        </AgGridContainer>
    )
});