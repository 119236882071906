import { useDashboard } from "../useDashboard";
import { DealSummaryView } from "./DealSummaryView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";

export const DealSummaryDashboard = ({ visible, }) => {
    const dashboardKey = 'deal-summary';
    const useShared = true;

    const Dashboard = useDashboard({ dashboardKey, useShared, ViewComponent: DealSummaryView, AddNewOrUpdateDialog, visible, });
    return Dashboard;
}