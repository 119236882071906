import { Box } from "@mui/material";
import { ConfirmDealButton, EditDealButton, VoidDealButton } from "../../RibbonButtons";
import { useFormContext } from "react-hook-form";

export const DealActionsGroup = ({ expanded, formId, selectedRow, gridRef }) => {
    const toConfirm = selectedRow?.Status?.toLowerCase() !== 'confirmed';
    const confirmButtonText = toConfirm ? 'Confirm' : 'Unconfirm';
    const { watch } = useFormContext();
    const timezone = watch('timezone');

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', p: 1, }}>
            <EditDealButton
                expanded={expanded}
                disabled={!selectedRow}
                deal={selectedRow}
                formId={formId}
                fetchDealData={true}
                timezone={timezone}
            />

            <ConfirmDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                gridRef={gridRef}
                label={confirmButtonText}
            />

            <VoidDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                gridRef={gridRef}
            />

        </Box>
    )
}