import { useState, memo, useCallback } from 'react';
import { Stack, FormControlLabel, Switch, FormGroup, } from "@mui/material"
import { RefreshButton } from "../../RibbonButtons";
import { useFormContext } from "react-hook-form";

export const GridActionsGroup = ({ formId, expanded, setHourlyColumnVisibility, handleFetchData, }) => {
    const { getValues } = useFormContext();
    const showMWColumns = getValues('showMWColumns');
    const showPriceColumns = getValues('showPriceColumns');
    const [MWColumns, setMWColumns] = useState(showMWColumns ?? false);
    const [priceColumns, setPriceColumns] = useState(showPriceColumns ?? false);
    
    const toggleShowMWColumns = (_, newVal) => {
        setHourlyColumnVisibility(prev => ({
            ...prev,
            showMWColumns: newVal
        }));

        setMWColumns(!MWColumns);

    }

    const toggleShowPriceColumns = (_, newVal) => {
        setHourlyColumnVisibility(prev => ({
            ...prev,
            showPriceColumns: newVal
        }));

        setPriceColumns(!priceColumns);

    }

    return (
        <Stack direction='row' spacing={2} alignItems='stretch' sx={{ p: 1, pt: 2, display: 'flex', flexWrap: 'wrap', rowGap: '16px' }}>
            <RefreshButton
                formId={formId}
                expanded={expanded}
                onRefresh={handleFetchData}
            />

            <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormControlLabel
                    label="Show MW Columns"
                    slotProps={{
                        typography: { variant: 'caption', }
                    }}
                    control={
                        <Switch
                            checked={!!MWColumns}
                            onChange={toggleShowMWColumns}
                        />
                    }
                />
            </FormGroup>

            <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormControlLabel
                    label="Show Price Columns"
                    slotProps={{
                        typography: { variant: 'caption', }
                    }}
                    control={
                        <Switch
                            checked={!!priceColumns}
                            onChange={toggleShowPriceColumns}
                        />
                    }
                />
            </FormGroup>
        </Stack>
    );
}