import { RibbonButton } from "../Ribbon";
import Refresh from "@mui/icons-material/Refresh";
import { useFormContext } from "react-hook-form";

export const RefreshButton = ({ formId, expanded, onRefresh, ...buttonProps }) => {
    const { handleSubmit } = useFormContext();

    function handleRefresh() {
        handleSubmit(onRefresh)();
    }

    return (
        <RibbonButton
            id={`${formId}-refresh`}
            icon={<Refresh fontSize='small' />}
            label={'Refresh'}
            expanded={expanded}
            onMouseDown={handleRefresh}
            {...buttonProps}
        />
    )
}