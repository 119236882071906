import { memo, useState, useRef } from "react";
import { Drawer } from "../StyledDrawer";
import { Divider, List, Tooltip, ListItemButton, ListItemIcon, ListItemText, } from "@mui/material";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import TableChartIcon from '@mui/icons-material/TableChart';
import ConstructionIcon from '@mui/icons-material/Construction';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CellTowerIcon from '@mui/icons-material/CellTower';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DifferenceIcon from '@mui/icons-material/Difference';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useNavigate } from "react-router-dom";
import StormIcon from '@mui/icons-material/Storm';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const Menu = (props) => {
  const { view } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const timerRef = useRef(null);

  function handleSelection(view) {
    navigate(`/dealrizz/${view}`);
  }

  const handleMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      setOpenDrawer(true);
    }, 500); // 1 second delay
  };

  const handleMouseLeave = () => {
    clearTimeout(timerRef.current); // Clear the timer if the mouse leaves
    setOpenDrawer(false);
  };

  return (
    <Drawer variant="permanent" open={openDrawer} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <List>
        <ListItemButton
          selected={view === 'dealsummary'}
          onClick={() => handleSelection('dealsummary')}>
          <ListItemIcon>
            <SummarizeIcon />
          </ListItemIcon>
          <ListItemText primary="Deal Summary" />
        </ListItemButton>

        <ListItemButton
          selected={view === 'positionreport'}
          onClick={() => handleSelection('positionreport')}>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Deal Position" />
        </ListItemButton>

        <ListItemButton
          selected={view === 'positionmanagement'}
          onClick={() => handleSelection('positionmanagement')}>
          <ListItemIcon>
            <ManageSearchIcon />
          </ListItemIcon>
          <ListItemText primary="Position MGMT" />
        </ListItemButton>

        <ListItemButton
          selected={view === 'lmps'}
          onClick={() => handleSelection('lmps')}>
          <ListItemIcon>
            <PriceCheckIcon />
          </ListItemIcon>
          <ListItemText primary="LMP Heatmap" />
        </ListItemButton>

        <Divider />

        <Tooltip title="Create and adjust tags." arrow placement="right">
          <ListItemButton
            selected={view === 'tagreport'}
            onClick={() => handleSelection('tagreport')}>
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Tagging" />
          </ListItemButton>
        </Tooltip>

        <Divider />

        <ListItemButton
          selected={view === 'schedulesummary'}
          onClick={() => handleSelection('schedulesummary')}>
          <ListItemIcon>
            <EventNoteIcon />
          </ListItemIcon>
          <ListItemText primary="Schedule Summary" />
        </ListItemButton>

        <ListItemButton
          selected={view === 'scheduling'}
          onClick={() => handleSelection('scheduling')}>
          <ListItemIcon>
            <CalendarViewMonthIcon />
          </ListItemIcon>
          <ListItemText primary="Scheduling" />
        </ListItemButton>

        {/*<ListItemButton
          selected={view === 'unscheduledmws'}
          onClick={() => handleSelection('unscheduledmws')}>
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText primary="Unscheduled MWs" />
        </ListItemButton>*/}

        <Divider />

        <Tooltip title="See the details of the TSRs recently queried from OASIS." arrow placement="right">
          <ListItemButton
            selected={view === 'tsractivity'}
            onClick={() => handleSelection('tsractivity')}>
            <ListItemIcon>
              <LocalActivityIcon />
            </ListItemIcon>
            <ListItemText primary="TSR Activity" />
          </ListItemButton>
        </Tooltip>

        <ListItemButton
          selected={view === 'transmissionposition'}
          onClick={() => handleSelection('transmissionposition')}>
          <ListItemIcon>
            <CellTowerIcon />
          </ListItemIcon>
          <ListItemText primary="Trans Position" />
        </ListItemButton>

        <ListItemButton
          selected={view === 'transmissiondealsummary'}
          onClick={() => handleSelection('transmissiondealsummary')}>
          <ListItemIcon>
            <AnalyticsIcon />
          </ListItemIcon>
          <ListItemText primary="Trans Deal Summary" />
        </ListItemButton>

        <ListItemButton
          selected={view === 'pathbuilder'}
          onClick={() => handleSelection('pathbuilder')}>
          <ListItemIcon>
            <ConstructionIcon />
          </ListItemIcon>
          <ListItemText primary="Pathway Builder" />
        </ListItemButton>

        <Tooltip title="View and edit your transaction alerts." arrow placement="right">
          <ListItemButton
            selected={view === 'transactionqueue'}
            onClick={() => handleSelection('transactionqueue')}>
            <ListItemIcon>
              <TrackChangesIcon />
            </ListItemIcon>
            <ListItemText primary="Trans Q" />
          </ListItemButton>
        </Tooltip>

        <Divider />

        <ListItemButton
          selected={view === 'indexdailyprice'}
          onClick={() => handleSelection('indexdailyprice')}>
          <ListItemIcon>
            <RequestQuoteIcon />
          </ListItemIcon>
          <ListItemText primary="Index Daily Price" />
        </ListItemButton>

        <ListItemButton
          selected={view === 'forecast'}
          onClick={() => handleSelection('forecast')}>
          <ListItemIcon>
            <StormIcon />
          </ListItemIcon>
          <ListItemText primary="Forecast" />
        </ListItemButton>

      </List>
    </Drawer>
  )
}

export default memo(Menu);