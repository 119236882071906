import { useEffect, useCallback, } from "react";
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from "lodash";

export const ViewContextProvider = ({ schema, defaults, children, onSubmit, }) => {
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaults,
        mode: 'onBlur',
    });

    const { handleSubmit, watch, reset, } = methods;

    const onSubmitDebounced = useCallback(debounce(onSubmit, 100), [onSubmit]);

    useEffect(() => {
        const subscription = watch(handleSubmit(onSubmitDebounced));
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);

    useEffect(() => {
        reset(defaults);
    }, [defaults]);

    return (
        <FormProvider {...methods}>
            {children}
        </FormProvider>
    );
}