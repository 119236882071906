import { useDashboard } from "../useDashboard";
import { LMPGrid } from "./LMPGrid";
import AddNewOrUpdateDialog from "./AddNewOrUpdateDialog";

export const LMPs = ({ visible, }) => {
    const dashboardKey = 'LMPGrids';
    const useShared = true;

    const Dashboard = useDashboard({ dashboardKey, useShared, ViewComponent: LMPGrid, AddNewOrUpdateDialog, visible, });
    return Dashboard;
}