import { IconButton, Stack, } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, } from "@mui/x-date-pickers";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import dayjs from "dayjs";
import FormDatePicker from "../../../FormControls/FormDatePicker";
import { useFormContext } from "react-hook-form";

export const ViewForm = ({ onSubmit, formId, expanded }) => {
    const { handleSubmit, formState: { errors }, control, getValues, setValue, watch } = useFormContext();
    const timezone = watch('timezone');

    const handleDateUpdate = (key, value) => {
        //make sure start date is before stop date
        const start = getValues('startDate');
        const end = getValues('endDate');
        if ((key === 'startDate' && dayjs(end).isBefore(value)) || (key === 'endDate' && dayjs(start).isAfter(value))) {
            setValue('startDate', value);
            setValue('endDate', value);
        } else {
            setValue(key, value);
        }
    }

    function handleIncrementDates(days) {
        const startDate = getValues('startDate');
        const endDate = getValues('endDate');
        setValue('startDate', startDate.add(days, 'day'));
        setValue('endDate', endDate.add(days, 'day'));
    }

    return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <Stack direction='row' spacing={2} alignItems='stretch' sx={{ p: 1, pt: 2, display: 'flex', flexWrap: 'wrap', rowGap: '16px' }}>
                <div style={{ display: 'flex', alignItems: 'start' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDates(-1);
                        }}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <FormDatePicker
                            name='startDate'
                            control={control}
                            label="Flow Date Start"
                            onChange={(newValue) => handleDateUpdate('startDate', newValue?.startOf('date'))}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.startDate,
                                    helperText: expanded && (errors.startDate?.message ?? `Time Zone: ${timezone?.split(' ')[0]}`),
                                    sx: { width: '170px', },
                                }
                            }}
                        />&nbsp;
                        <FormDatePicker
                            name='endDate'
                            control={control}
                            label="Flow Date End"
                            onChange={(newValue) => handleDateUpdate('endDate', newValue?.startOf('date'))}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.endDate,
                                    helperText: errors.endDate?.message,
                                    sx: { width: '170px', },
                                }
                            }}
                        />
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDates(1);
                        }}>
                            <ArrowRightIcon />
                        </IconButton>
                    </LocalizationProvider>
                </div>

            </Stack>
        </form>
    );
}