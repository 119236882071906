import { RibbonButton } from "../Ribbon";
import { LookupValuesProvider } from "../DealEntry/LookupContext";
import { DealEntryDialog } from "../DealEntry/DealEntryDialog";
import { useState } from "react";
import Publish from "@mui/icons-material/Publish";

export const CreateDealButton = ({ expanded, disabled, formId, ...buttonProps }) => {
    const [openDealEntryDialog, setOpenDealEntryDialog] = useState(false);

    return (
        <span>
            <LookupValuesProvider>
                <DealEntryDialog
                    open={openDealEntryDialog}
                    dealToEdit={null}
                    handleClose={() => setOpenDealEntryDialog(false)}
                    handleSave={() => {
                        setOpenDealEntryDialog(false);
                        //programmatically click the refresh button to update the grid
                        document.getElementById(`${formId}-refresh`).click();
                    }}
                />
            </LookupValuesProvider>
            <RibbonButton
                onMouseDown={() => {
                    setOpenDealEntryDialog(true);
                }}
                icon={<Publish fontSize='small' />}
                label={'Create'}
                expanded={expanded}
                disabled={disabled}
                {...buttonProps}
            />
        </span>
    )
}