import { useRef, useMemo, useCallback, forwardRef } from "react"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import dayjs from "dayjs"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { tooltipRenderer, redPastMarkerFormatter, } from "../Utils"
import { DealDetailCellRenderer } from "./DealDetailCellRenderer"
import useHubObject from "../../HubContext/useHubObject"
import { useUserInfo } from "../../UserInfoContext"
import { useApi } from "../../useApi"
import { useDashboardLayout } from "../useDashboardLayout"
import { useFormContext } from "react-hook-form"

export const ScheduleSummaryGrid = forwardRef(({ setSelectedDeal, setSelectedRow, getRowId, layoutStorageKey, silentUpdate, }, ref) => {
    const { getValues } = useFormContext();
    const { layout, } = getValues();
    const { columnTypes } = useColumnTypes();
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(ref);
    const userInfo = useUserInfo();
    const { enqueueSnackbar, headers, } = useApi();

    const silentRefresh = useCallback(async () => {
        enqueueSnackbar('Schedule changes detected. Updating...', { variant: 'info' });
        return silentUpdate(getValues());
    }, [getValues]);

    useHubObject({
        action: silentRefresh,
        allowedMessages: ['scheduleUpdate'],
        callbackDependencies: [headers],
        predicate: (obj) => {
            return (obj.tenantID.toString() === userInfo.tenantId?.toString())
                && (obj.userName !== userInfo.userName); //ignore own updates
        },
        debounceOptions: { maxWait: 1000, leading: true, },
        wait: 500,
    });

    const baseColDefs = useMemo(() => ([
        { field: "scheduleID", headerName: "Schedule ID", cellRenderer: 'agGroupCellRenderer', minWidth: 150, },
        { field: "Schedule_Status", initialWidth: "150px", headerName: "Schedule Status" },
        { field: "followTag", initialWidth: "100px", headerName: "Dynamic" },
        { field: "linkedTags", initialWidth: "200px", headerName: "Linked Tags" },
        {
            headerName: "Net Profile",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data.scheduleProfileJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        { field: "CA_Schedule", initialHide: "true", headerName: "CA Schedule" },
        { field: "CA_Status", initialHide: "true", headerName: "CA Status" },
        { field: "CPSE", headerName: "CPSE" },
        { field: "Tag_Code", headerName: "Tag Code" },
        { field: "Req_Type", headerName: "Req Type" },
        { field: "Req_Status", headerName: "Req Status" },
        { field: "GPE", headerName: "GPE" },
        { field: "GCA", headerName: "GCA" },
        { field: "LCA", headerName: "LCA" },
        { field: "LSE", headerName: "LSE" },
        { field: "TPs", headerName: "TPs" },
        { field: "Sched_Entities", headerName: "Sched Entities" },
        { field: "TX_Cust", headerName: "TX Cust" },
        { field: "Tag_Last_Action", headerName: "Tag Last Action" },
        { field: "Tag_Status", headerName: "Tag Status" },
        { field: "Tag_Error", headerName: "Tag Error" },
        { field: "Tag_Start_Time_MPT", headerName: "Tag Start Time MPT", type: 'dateColumn', },
        { field: "Tag_Stop_Time_MPT", headerName: "Tag Stop Time MPT", type: 'dateColumn', },
        { field: "Schedule_Comment", headerName: "Schedule Comment" },
        { field: "Sequential_Schedule", headerName: "Sequential Schedule" },
        { field: "Buyer", headerName: "Buyer" },
        { field: "Seller", headerName: "Seller" },
        { field: "Creator", headerName: "Creator" },
        { field: "Exporter", headerName: "Exporter" },
        { field: "Modifier", headerName: "Modifier" },
        { field: "Product", headerName: "Product" },
        { field: "Misc", initialHide: "true", headerName: "Misc" },
        { field: "Event_Origin", headerName: "Event Origin" },
        { field: "Start_Date", headerName: "Start Date", type: 'dateColumn', },
        { field: "End_Date", headerName: "End Date", type: 'dateColumn', },
        { field: "Creation_Time_MPT", headerName: "Creation Time MPT", type: 'dateColumn', },
        { field: "Export_Time_MPT", headerName: "Export Time MPT", type: 'dateColumn', },
        { field: "Modified_Time_MPT", headerName: "Modified Time MPT", type: 'dateColumn', },
        { field: "Source", headerName: "Source" },
        { field: "Sink", headerName: "Sink" },
        { field: "POR", headerName: "POR" },
        { field: "POD", headerName: "POD" },
        { field: "Gen_MW", headerName: "Gen MW" },
        { field: "Purchase_Book", headerName: "Purchase Book" },
        { field: "Sale_Book", headerName: "Sale Book" },
        { field: "PSE_Comment", headerName: "PSE Comment" },
        { field: "Purchase_Term", headerName: "Purchase Term" },
        { field: "Sale_Term", headerName: "Sale Term" },
        { field: "Schedule_Book", headerName: "Schedule Book" },
        { field: "Schedule_Term", headerName: "Schedule Term" },
        { field: "Slice_Transaction", headerName: "Slice Transaction" },
        { field: "Interface", headerName: "Interface" },
        { field: "CEC_ID", headerName: "CEC ID" },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        minWidth: 100,
        flex: 1,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: { layout, },
        baseColDefs,
        defaultColDef,
    });

    const styledColDefs = useMemo(() => {
        const firstVisibleColumn = colDefs.find(colDef => !colDef.hide && !colDef.initialHide);
        //set checkbox selection to first visible column
        if (firstVisibleColumn) {
            firstVisibleColumn.checkboxSelection = true;
        }

        return colDefs.map(colDef => ({
            ...colDef,
            cellClassRules: highlightingCellClassRules,
        }));
    }, [colDefs, highlightingCellClassRules]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    function onGridReady(params) {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            // group columns configured to use the Group Column Filter
            filter: 'agGroupColumnFilter',
        };
    }, []);

    const handleSelectionChanged = useCallback(() => {
        const selected = ref.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);

    const detailCellRenderer = useMemo(() => {
        return (props) => DealDetailCellRenderer(props);
    }, []);

    const detailRendererParams = useMemo(() => ({
        setSelectedRow: setSelectedDeal,
    }), [setSelectedDeal]);

    return (
        <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                ref={ref}
                getRowId={getRowId}
                columnDefs={styledColDefs}
                enableCharts
                autoGroupColumnDef={autoGroupColumnDef}
                onFirstDataRendered={handleFirstDataRendered}
                rowSelection="single"
                rowMultiSelectWithClick
                onSelectionChanged={handleSelectionChanged}
                grandTotalRow={"bottom"}
                onCellMouseOver={handleCellMouseOverHighlight}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                statusBar={defaultStatusBar}
                sideBar={sideBar}
                isRowMaster={(rowData) => !!rowData.dealJson}
                masterDetail
                detailCellRenderer={'detailCellRenderer'}
                detailCellRendererParams={detailRendererParams}
                detailRowAutoHeight
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    detailCellRenderer: detailCellRenderer,
                }}
            />
        </AgGridContainer>
    )
});