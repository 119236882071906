import { useImperativeHandle, useState, forwardRef, useRef, useCallback, useMemo } from "react"
import { Box, } from "@mui/material"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { useChart } from "../useChart"
import { TagReportRibbon } from "./Ribbon/TagReportRibbon"
import { ViewContextProvider } from "../ViewContextProvider"
import * as yup from 'yup';
import dayjs from "dayjs"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { heatmapStyle, tooltipRenderer, handleUpdateTotalRows, redPastMarkerFormatter, } from "../Utils"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { ProfileDetailRenderer } from "./ProfileDetailRenderer";
import debounce from "lodash/debounce"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { useApi } from "../../useApi";
import { useDashboardFetch } from "../useDashboardFetch";
import { useDashboardLayout } from "../useDashboardLayout";
import { useLayoutFunctions } from "../../useLayoutFunctions"

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    startDate: yup.date().required('Start Date is required'),
    stopDate: yup.date().required('Stop Date is required'),
});

export const TagReportView = forwardRef(({ view, }, ref) => {
    const { ChartContainer, createChartContainer, popupParent, } = useChart({ id: view.id });
    const [showChart, setShowChart] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedAdjustment, setSelectedAdjustment] = useState();
    const gridRef = useRef();
    const layoutStorageKey = `deal-rizz-tag-report-grid-layout-${view.id}`;
    const toolbarFormId = `tag-report-toolbar-form-${view.id}`;
    const { columnTypes } = useColumnTypes();
    const paletteRef = useRef({ showHeatmap: false });
    const [fetchContext, setFetchContext] = useState(view);
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);
    const { post, logAction, headers, apiUrlPrefix, enqueueSnackbar, } = useApi();
    

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        source: '',
        excludeMarketPathPSE: '',
        onlyShowNonZeroCuts: false,
        ...view,
        startDate: dayjs(),
        stopDate: dayjs(),
    }), [view]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });


    const baseColDefs = useMemo(() => ([
        {
            headerName: 'Tag Idx',
            field: 'tagIdx',
            checkboxSelection: true,
            cellDataType: 'text',
            cellRenderer: 'agGroupCellRenderer',
        },
        {
            headerName: 'Tag Code',
            field: 'tagCode',
        },
        {
            headerName: 'Tag Status',
            field: 'tagStatus',
        },
        {
            headerName: 'Date',
            field: 'tagDate',
            type: 'dateColumn',
            dateFormat: 'MM/DD/YYYY',
        },
        { field: 'firstPOR', headerName: 'First POR', },
        { field: 'lastPOD', headerName: 'Last POD', },
        {
            field: 'Source',
        },
        {
            field: 'Sink',
        },
        {
            headerName: 'Full Tag Profile',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            initialHide: true,
            initialWidth: 300,
            valueGetter: (params) => {
                const json = params.data?.fullTagProfile;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.StartTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                            { x: dayjs(next.StopTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        {
            headerName: 'Tag Profile',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            initialWidth: 300,
            valueGetter: (params) => {
                const { data, } = params;
                if (!data) return [];
                return [...Array.from({ length: 26 }).keys()].reduce((acc, next) => {
                    const hour = next + 1;

                    acc.push(
                        { x: dayjs(data.tagDate).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour] ?? 0 },
                        { x: dayjs(data.tagDate).startOf('day').add(hour, 'hours').toDate(), y: data[hour] ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            // aggFunc: 'sum',
            // enableValue: true,
            editable: params => params.data.ProfileType?.toLowerCase() === 'current',
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 25,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            cellStyle: params => heatmapStyle(params, paletteRef),
        })),
        {
            headerName: 'OASIS #',
            field: 'OASIS Number',
        },
        {
            headerName: 'Deal Type',
            field: 'dealType',
        },
        {
            headerName: 'Counterparty',
            field: 'counterParty',
        },
        {
            headerName: 'Composite State',
            field: 'compositeState',
        },
        {
            headerName: 'PSE Contact',
            field: 'PSE Contact',
        },
        {
            headerName: 'Profile Type',
            field: 'ProfileType',
        },
        {
            headerName: 'CC List',
            field: 'CC List',
            valueFormatter: params => {
                //insert spaces after each comma for readability
                return params.value?.split(',').join(', ');
            },
        },
        {
            field: 'LCA',
        },
        {
            field: 'GCA',
        },
        {
            field: 'CPSE',
        },
        {
            field: 'Buy/Sell',
        },
        {
            field: 'Start Time',
            type: 'dateColumn',
        },
        {
            field: 'Stop Time',
            type: 'dateColumn',
        },
        {
            field: 'LastUpdated',
            headerName: 'Last Updated',
            type: 'dateColumn',
            aggFunc: 'max',
            enableValue: true,
            enableRowGroup: false,
            valueFormatter: params => {
                //the time is in UTC, so we need to convert it to the local time zone. Shift the time by the offset.
                const offset = dayjs().utcOffset();
                return params.value ? dayjs(params.value).add(offset, 'minute').format('MM/DD/YYYY HH:mm') : '';
            },
        },
        {
            headerName: 'Schedule ID',
            field: 'scheduleId',
        },
        {
            headerName: 'Deal Id',
            field: 'dealId',
        },
        {
            field: 'Notes',
        },
        {
            field: 'Market Path',
        },
        {
            field: 'Total',
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        minWidth: 100,
        //enableCellChangeFlash: true,
    }), []);

    const getRowId = useCallback((params) => {
        const { data, } = params;
        return `${data.tagIdx}-${data.ProfileType}-${data['Start Time']}-${data['Stop Time']}`;
    }, []);

    const { loadAndApplyData, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_TagReport_FetchSummary_v2',
        fetchParamKeys: ['userGuid', 'timezone', 'startDate', 'stopDate', 'onlyShowNonZeroCuts', 'excludeMarketPathPSE'],
        gridRef,
        getRowId,
    });

    const { applyFilters, applyLayout, colDefs, layoutPanel, captureLayout, } = useDashboardLayout({
        gridRef,
        layoutStorageKey,
        context: view,
        baseColDefs,
        defaultColDef,
    });

    function handleFetchData(data) {
        logAction('User fetched Tag Report Data', 'Tag Reports', data);
        setFetchContext({ ...fetchContext, ...data });
        loadAndApplyData({
            userGuid: headers.userGuid,
            ...data,
            startDate: dayjs(data.startDate).format('MM/DD/YYYY'),
            stopDate: dayjs(data.stopDate ?? data.startDate).format('MM/DD/YYYY'),
            onlyShowNonZeroCuts: data.onlyShowNonZeroCuts ? 1 : 0,
        });
    }

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    function onGridReady(params) {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const handleRowSelected = useCallback((params) => {
        const selectedRows = gridRef.current?.api?.getSelectedRows();
        setSelectedRows(selectedRows);
    }, []);

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, 'tagIdx');
    }, []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(gridRef, paletteRef, 'tag-report-palette'),
        ]
    }), []);

    const detailCellRenderer = useMemo(() => {
        return (props) => ProfileDetailRenderer(props);
    }, []);

    const detailRendererParams = useMemo(() => ({
        context: fetchContext,
        setSelectedAdjustment,
    }), [fetchContext]);

    const handleRowGroupOpened = useCallback((params) => {
        if (params.node.expanded && !params.node.selected) {
            params.node.setSelected(true);
        }
    }, []);

    function handleCellValueChanged(params) {
        const { data, } = params;
        if (data.tagStatus?.toLowerCase() === 'draft') {
            handleDraftTagUpdate(data);
        }
    }

    const handleDraftTagUpdate = useCallback(debounce((data) => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_TagReport_ModifyEnergyOnDraftTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${data.tagIdx}`
            + `&parm=${view.timezone}`

        post(url, data).then(response => {
            enqueueSnackbar('Draft modification saved.', { variant: 'info' });
        });
    }, 300), [headers]);

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box sx={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
                <TagReportRibbon
                    toolbarFormId={toolbarFormId}
                    selectedRows={selectedRows}
                    selectedAdjustment={selectedAdjustment}
                    handleRefresh={handleFetchData}
                    gridRef={gridRef}
                    setShowChart={setShowChart}
                    showChart={showChart}
                />
                <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }}>
                    <Box onMouseLeave={clearHighlighting} sx={{ width: '100%', height: '100%' }}>
                        <AgGridReact
                            {...defaultGridOptions}
                            containerStyle={{ width: '100%', height: '100%' }}
                            getRowId={getRowId}
                            ref={gridRef}
                            columnDefs={styledColDefs}
                            gridOptions={gridOptions}
                            onRowSelected={handleRowSelected}
                            onFilterChanged={updateTotalRows}
                            onRowDataUpdated={updateTotalRows}
                            onCellValueChanged={handleCellValueChanged}
                            onRowGroupOpened={handleRowGroupOpened}
                            rowSelection="single"
                            rowMultiSelectWithClick
                            enableCharts
                            onFirstDataRendered={handleFirstDataRendered}
                            suppressAggFuncInHeader={true}
                            groupTotalRow={"bottom"}
                            groupSuppressBlankHeader={false}
                            onGridReady={onGridReady}
                            columnTypes={columnTypes}
                            statusBar={defaultStatusBar}
                            sideBar={sideBar}
                            masterDetail
                            detailRowAutoHeight
                            detailCellRenderer={'detailCellRenderer'}
                            detailCellRendererParams={detailRendererParams}
                            onCellMouseOver={handleCellMouseOverHighlight}
                            components={{
                                layoutToolPanel: LayoutToolPanel,
                                paletteToolPanel: PaletteToolPanel,
                                detailCellRenderer: detailCellRenderer,
                            }}
                        />
                    </Box>
                </AgGridContainer>
                <Box sx={{ display: showChart ? 'flex' : 'none', flex: 1, width: '100%' }} id='tag-report-chart-container'>
                    <ChartContainer style={{ width: '100%' }} />
                </Box>
            </Box>
        </ViewContextProvider>
    )
});