import { AgGridReact } from "ag-grid-react";
import { forwardRef, useMemo, } from "react";
import _ from 'lodash';
import { columnPanel, filterPanel } from "../../ToolPanels/DefaultToolPanels";
import { useTheme } from "@emotion/react";
import dayjs from "dayjs";
import { tooltipRenderer, configureSparkline, } from "./UtilFunctions";
import { defaultGridOptions } from "../../AgGrid/defaultGridProps";

export const ProfileDataGrid = forwardRef((props, ref) => {
    const { data, tsrKey, startDateTime, endDateTime, updateProfileChart, onSelectionChanged, setLegHighlight, } = props;
    const theme = useTheme();

    const markerFormatter = (params) => {
        const { min, highlighted } = params;
        return {
            size: highlighted ? 3 : 1,
            enabled: true,
            fill: params.yValue <= 0 ? theme.palette.primary.red : theme.palette.primary.green,
            stroke: params.yValue <= 0 ? theme.palette.primary.red : theme.palette.primary.green,
        };
    };

    const colDefs = useMemo(() => [
        {
            headerName: 'TSR Key',
            valueGetter: (params) => {
                const { PointOfReceipt, Provider, PointOfDelivery } = params?.data ?? {};
                if (PointOfReceipt && Provider && PointOfDelivery) {
                    return `${params.data.PointOfReceipt}-${params.data.Provider}-${params.data.PointOfDelivery}`;
                } else {
                    return "";
                }
            },
            checkboxSelection: true,
            rowDrag: true,
        },
        ...generateProfileColumns(),
        {
            headerName: 'Provider',
            field: 'Provider',
        },
        {
            headerName: "PointOfReceipt",
            field: "PointOfReceipt",
        },
        {
            headerName: 'PointOfDelivery',
            field: 'PointOfDelivery',
        },
        {
            headerName: 'Capacity',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            valueGetter: (params) => {
                if (params.data.Capacity) {
                    return configureSparkline(JSON.parse(params.data.Capacity));
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: markerFormatter,
                    }
                },
            },
        },
        {
            headerName: 'Offer Price',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            valueGetter: (params) => {
                if (params.data.OfferPrice) {
                    return configureSparkline(JSON.parse(params.data.OfferPrice));
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: markerFormatter,
                    }
                },
            },
        },
    ], [startDateTime, endDateTime]);

    function generateProfileColumns() {
        const columns = [];
        let start = dayjs(startDateTime).add(1, 'hour');
        const end = dayjs(endDateTime).add(1, 'hour');
        while (start.isBefore(end)) {
            columns.push({
                headerName: start.format('MM/DD/YY HH:mm'),
                field: start.format('MM/DD/YY HH:mm'),
                children: [
                    { headerName: "Q", initialWidth: '120px', field: `ProfileInfo.${start.format('MM/DD/YY HH:mm')}.Q`, editable: true, },
                    { headerName: "P", initialHide: true, initialWidth: '60px', field: `ProfileInfo.${start.format('MM/DD/YY HH:mm')}.P`, editable: true, },
                ],
            });
            start = start.add(1, 'hour');
        }
        return columns;
    }

    const defaultColDef = useMemo(() => ({
        editable: false,
        sortable: true,
        filter: "agMultiColumnFilter",
        resizable: true,
        floatingFilter: true,
        enableRowGroup: true,
    }), []);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                columnPanel,
                filterPanel,
            ],
            position: 'right',
        }
    }, []);

    const gridOptions = {
        rowClassRules: {
            "ag-row-selected": params => !!params.data._highlighted || params.node.selected,
            "ag-row-hover": params => !!params.data._highlighted,
        },
    };

    const noRowsOverlayText = 'Rows will appear where when data is added to the workspace.'

    function getRowId(params) {
        return tsrKey(params.data);
    }

    function onCellValueChanged(params) {
        updateProfileChart(data);
    }

    function handleMouseOver(params) {
        const node = params.node;
        const legKey = tsrKey(node.data);
        setLegHighlight(legKey, true);
    }

    function handleMouseOut(params) {
        const node = params.node;
        const legKey = tsrKey(node.data);
        setLegHighlight(legKey, false);
    }

    return (
        <AgGridReact
            {...defaultGridOptions}
            ref={ref}
            rowData={data}
            getRowId={getRowId}
            gridOptions={gridOptions}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            sideBar={sideBar}
            onCellValueChanged={onCellValueChanged}
            //onRowDataUpdated={onCellValueChanged}
            onCellMouseOver={handleMouseOver}
            onCellMouseOut={handleMouseOut}
            rowSelection="multiple"
            onSelectionChanged={onSelectionChanged}
            overlayNoRowsTemplate={`<span style="width: 70%; font-size: 20px">${noRowsOverlayText}</span>`}
        />
    );
});