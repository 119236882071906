import { Box } from "@mui/material"
import { CreateDealButton, EditDealButton, ConfirmDealButton, VoidDealButton, ExtendDealButton } from "../../RibbonButtons"

export const DealActionsGroup = ({ expanded, formId, selectedRow, handleFetchData, gridRef, }) => {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', p: 1, }}>
            <CreateDealButton
                expanded={expanded}
                formId={formId}
            />

            <EditDealButton
                expanded={expanded}
                disabled={!selectedRow}
                deal={selectedRow}
                formId={formId}
            />

            <ConfirmDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                gridRef={gridRef}
            />

            <VoidDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                gridRef={gridRef}
            />

            <ExtendDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                formId={formId}
                onRefresh={handleFetchData}
                gridRef={gridRef}
            />

        </Box>
    )
}