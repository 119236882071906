import { DealPositionView } from "./DealPositionView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { useDashboard } from "../useDashboard";

export const DealPositionDashboard = ({ visible, }) => {
    const dashboardKey = 'position-report';
    const useShared = true;

    const Dashboard = useDashboard({ dashboardKey, useShared, ViewComponent: DealPositionView, AddNewOrUpdateDialog, visible, });
    return Dashboard;
}